export default {
  common: {
    appName: "دۇكانچى سېستىمىسى",
    appNameQrcode: "ئىككىلىك كود پائالىيەت سىستېمىسى",
    example: "مەسلەن",
    mobile: "يانفۇن",
    cancelText: "قالدۇرۇش",
    delete: "ئۆچۈرۈش",
    developingFeature: "بۇ ئىقتىدار تېخى ئېچىلىۋاتىدۇ ...",
    yuan: "يۈەن",
    minute: "مىنۇت",
    dayUnit: "كۈن",
    delete: "ئۆچۈرۈش",
    day: {
      1: "دۈ",
      2: "سە",
      3: "چا",
      4: "پە",
      5: "جۈ",
      6: "شە",
      7: "يە",
    },
    home: "باش بەت",
    search: "ئىزدەش",
    statusLabel: "ھالىتى",
    refresh: "يېڭىلاش",
    more: "تېخىمۇ كۆپ",
    richTextPlaceHolder: "مەزمۇننى كىرگۈزۈڭ",
    orderDescription: "سان قانچە چوڭ بولسا شۇنچە ئالدىغا تىزىلسا",
    independentCommissionDescription:
      "ئايرىم پىرسەنت بېكىتىلسە، سىستىما ئايرىم پىرسەنت بويىچە بىر تەرەپ قىلىدۇ، ۋاكالەتچى دەرىجىسىدىكى پىرسەنت ئويلىشىلمايدۇ.",
    yes: "ھەئە",
    no: "ياق",
  },
  base: {
    deliveryman: "توشۇغۇچى:",
    modal: {
      successTitle: "مەشغۇلات مۇۋەپپەقىيەتلىك بولدى",
      errorTitle: "مەشغۇلات مەغلۇپ بولدى",
      warningTitle: "ئاگاھلاندۇرۇش",
      infoTitle: "ئەسكەرتىش",
      confirmTitle: "مەشغۇلاتىڭىزنى راستىنلا داۋاملاشتۇرامسىز ؟",
      knowText: "بىلدىم",
      okText: "ھەئە",
      cancelText: "ياق",
    },
    api: {
      err401:
        "سىزنىڭ ئەزا ئۇچۇرىڭىزنىڭ ۋاقتى ئۆتۈپ كېتىپتۇ، بۇندىن كىيىنكى مەشغۇلاتلىرىڭىزنىڭ نورمال" +
        " داۋاملىشىشى ئۈچۈن قايتىدىن كىرىپ ئۇچۇرىڭىزنى يېڭىلىۋېلىڭ",
      err408:
        "ئۇچۇر ئېلىش ۋاقتى چەكتىن ئېشىپ كەتتى، مەشغۇلاتىڭىزنى قايتىلاپ ياكى بەتنى يېڭىلاپ سىناپ بېقىڭ",
      err422Title: "تەمىنلىگەن ئۇچۇرىڭىز تەلەپكە ئۇيغۇن ئەمەس",
      err422Empty:
        "تەمىنلىگەن ئۇچۇرىڭىز تەلەپكە ئۇيغۇن ئەمەس، ئۇچۇرلىرىڭىزنى تەپسىلي تەكشۈرگەندىن كىيىن سىناپ بېقىڭ",
      err429:
        "مەشغۇلاتىڭىز بەك تېز بولۇپ كەتتى، بىر ئاز ئارام ئېلىۋېلىپ ئاندىن داۋاملاشتۇرۇڭ",
      err500: "مۇلازىمېتىردا مەسىلە كۆرۈلدى، قايتا سىناپ بېقىڭ",
      err503: "مۇلازىمېتىردا مەسىلە كۆرۈلدى، قايتا سىناپ بېقىڭ",
      errUnknown:
        "نامەلۇم خاتالىق يۈز بەردى، مەشغۇلاتىڭىزنى قايتىلاپ ياكى بەتنى يېڭىلاپ سىناپ بېقىڭ",
    },
  },
  notFound: {
    backToHome: "باش بەتكە قايتىش",
    backToPrev: "ئالدىنقى بەتكە قايتىش",
    refresh: "بەتنى يېڭىلاش",
  },
  login: {
    titleImage: "/assets/images/login-title-ug.png",
    phoneHint: "تېلېفون نومۇرىڭىز",
    phoneHintRequired: "تېلېفون نومۇرىڭىز قۇرۇق قالمىسۇن",
    phoneHintPrefix: "تېلېفون نومۇرىڭىز چوقۇم 1 بىلەن باشلانسۇن",
    phoneHintCount: "تېلېفون نومۇرىڭىز چوقۇم 11 خانلىق بولسۇن",
    parolHint: "پارولىڭىز",
    parolHintRequired: "پارولىڭىز قۇرۇق قالمىسۇن",
    parolHintMin: "پارولىڭىز 6 خانىدىن كەم بولمىسۇن",
    parolHintMax: "پارولىڭىز 16 خانىدىن كۆپ بولمىسۇن",
    login: "كىرىش",
    loggingin: "كىرىۋاتىدۇ ...",
    loginSuccess: "كىرىش مۇۋەپپەقىيەتلىك بولدى",
    title: "بۇلۇت بازار سودىگەر سۇپۇسى",
    selectShop: "دۇكان تاللاڭ",
    enter: "كىرىش",
    insertShopName: "دۇكان نامىنى كىرگۈزۈڭ",
    password: "مەخپى نومۇر",
    reset: "مەخپى نومۇر ئۆزگەرتىش",
    pass: "يېڭى مەخپى نومۇر",
    passAgain: "قايتا كىرگۈزۈڭ",
    notMatch: "ئىككى قېتىم كىگۈزگەن مەخپى نومۇر بىردەك ئەمەس",
    forget: "ئۇنتۇپ قاپتىمەن",
    codeHint: "تەستىق كودىنى كىرگۈزۈڭ",
    getCode: "تەستق كود",
  },
  home: {
    chartTime: {
      week: "7 كۈن",
      month: "30 كۈن",
      season: "90 كۈن",
    },
    loadingData: "ئىستاتىستىكا ئۇچۇرنى ئېلىۋاتىدۇ ...",
    loadingDataWarning:
      "ئىستاتىستىكا ئۇچۇرنى ئېلىۋاتىدۇ، بىر ئازدىن كىيىن سىناڭ",
    numbers: {
      qrcode: "ئەپچاق ئىككىلىك كودى",
      dailyOrderCount: "بۈگۈنكى ئۈنۈملۈك زاكاز سانى",
      dailyOrderCountUnit: "تال",
      yesterdayOrderCount: "تۈنۈگۈن %{count} تال",
      dailyOrderIncome: "بۈگۈنكى زاكاز كىرىم سوممىسى",
      yesterdayOrderIncome: "تۈنۈگۈن %{income} يۈەن",
      dailyOrderTurnover: "بۈگۈنكى سودا سوممىسى",
      yesterdayOrderTurnover: "تۈنۈگۈن %{income} يۈەن",
      monthlyOrderCount: "بۇ ئايلىق ئۈنۈملۈك زاكاز سانى",
      monthlyOrderCountUnit: "تال",
      prevMonthOrderCount: "ئالدىنقى ئايدا %{count} تال",
      monthlyOrderIncome: "بۇ ئايلىق زاكاز كىرىم سوممىسى",
      prevMonthOrderIncome: "ئالدىنقى ئايدا %{income} يۈەن",
    },
    chart: {
      countTitle: "زاكاز سانى يۈزلىنىشى",
      dateAlias: "چېسىلا",
      countAlias: "زاكاز سانى",
      incomeTitle: "زاكاز سوممىسى يۈزلىنىشى",
      incomeAlias: "زاكاز سوممىسى",
    },
  },
  main: {
    sideMenus: {
      home: "باش بەت",
      delivery: "توشۇش باشقۇرۇش",
      order: "زاكاز باشقۇرۇش",
      orderList: "زاكازلار تىزىملىكى",
      refundList: "پۇل قايتۇرۇش تىزىملىكى",
      downloadList: "جەدۋەل چۈشۈرۈش",
      import: "جەدۋەل كىرگۈزۈش",
      product: "مەھسۇلات باشقۇرۇش",
      productList: "مەھسۇلات تىزىملىكى",
      manageAds: "ئېلان باشقۇرۇش",
      sales: "تىجارەت",
      coupons: "ئېتىبار كارتىسى",
      holiday: "پائالىيەت",
      flash: "ۋاقىتلىق ئېتىبار",
      gift: "سوۋغات باشقۇرۇش",
      groupon: "تالىشىپ سېتىۋىلىش",
      ads: "سىيرىلما ئىلان",
      navs: "باش بەت سەھىپىسى",
      popups: "سەكلەپ چىقىدىغان ئىلان",
      splash: "ئېچىلىش ئىلانى",
      homeAd: "باش بەت ئىلانى",
      userCenterAd: "ئەزا مەركىزى ئىلانى",
      becomeAgentAd: "ھەمكارلاشقۇچىلار مەركىزى ئىلانى",
      mini: "ئەپچاق",
      miniSetting: "تەڭشىكى",
      merchantSetting: "دۇكان تەڭشىكى",
      wechatMerchantApplymentInfo: "ئۈندىدار دۇكاندار ئىلتىماس ئەھۋالى",
      serviceArea: "مۇلازىمەت رايونى",
      serviceAreaSetting: "رايون باشقۇرۇش",
      review: "باھا باشقۇرۇش",
      reviewList: "باھالار تىزىملىكى",
      finance: "مالىيە باشقۇرۇش",
      financeHome: "مالىيە باش بېتى",
      financeCheck: "ھېسابات تەكشۈرۈش",
      financeAccount: "ئاكونت باشقۇرۇش",
      financeDownload: "چۈشۈرۈش رايونى",
      promotion: "پائالىيەت باشقۇرۇش",
      promotionShop: "دۇكان پائالىيىتى",
      proxy: "ۋاكالەتچى باشقۇرۇش",
      proxyList: "ۋاكالەتچىلەر تىزىملىكى",
      agentLevel: "ۋاكالەتچى دەرىجىسى",
      commissionList: "پايدا تىزىملىكى",
      withdrawList: "نەقلەش خاتىرىسى",
      withdrawRequestList: "نەقلەش ئىلتىماس خاتىرىسى",
      withdrawCommissionList: "پايدا ئايرىش تىزىملىكى",
      sendMoney: "پۇل ئۇرۇش خاتىرىسى",
      users: "ئەزا باشقۇرۇش",
      usersList: "ئەزا تىزىملىكى",
      balance: "قالدۇق خاتىرىسى",
      topup: "پۇل تولۇقلاش خاتىرىسى",
      spend: "خەجلەش خاتىرىسى",
      reduce: "ئازلىتىش تارىخى",
      balanceUserList: "ئەزا تىزىملىكى(قالدۇقى بار)",
      shows: "دام-دام",
      showsList: "بارلىق",
      setting: "تەڭشەكلەر",
      settingShop: "دۇكان تەڭشەكلىرى",
      selectLang: "تىل تاللاش",
      live: "نەق مەيداق",
      liveList: "تىزىملىكى",
      lottery: "مۇكاپات باشقۇرۇش",
      lotteryList: "مۇكاپات خاتىرىسى",
      lotteryTheme: "مۇكاپات ئۇسلۇبى",
      redPacket: "قىزىل بولاق",
      article: "ماقالە باشقۇرۇش",
      lotteryEdit: "مۇكاپات تەھرىرلەش",
      manageWeapp: "ئەپچاق باشقۇرۇش",
      members: "ئالىي ئەزا",
      membersList: "تىزىملىك",
      membersCard: "ئەزالىق كارتىسى",
      membersOrder: "ئەزالىق زاكاسلار",
      media: "ھۆججەت باشقۇرۇش",
      mediaList: "ھۆججەت تىزىملىكى",
      storageSetting: "ساقلاش تەڭشىكى",
      tuangouShop: "دۇكان",
    },
    userMenu: {
      logout: "چېكىنىش",
    },
    statusMenu: {
      business: "تىجارەت باشلاش",
      noBusiness: "دۇكان تاقاش",
      statusBusiness: "تىجارەت قىلىۋاتىدۇ ...",
      statusNoBusiness: "تاقىلىپ بولدى",
    },
    search: "ئىزدەش",
    searchHint: "زاكاز نومۇرى، زاكاز تەرتىپى، تېلېفون نومۇرنى كىرگۈزۈڭ",
    alreadyInBusiness: "دۇكان تىجارەت قىلىۋاتىدۇ، ئۆزگەرتىش ھاجەتسىز",
    alreadyNoBusiness: "دۇكان تاقىلىپ بولدى، ئۆزگەرتىش ھاجەتسىز",
    updatingBusinessStatus: "دۇكاننىڭ تىجارەت ھالىتى يېڭىلىنىۋاتىدۇ ...",
    updateBusinessStatusSuccess:
      "دۇكاننىڭ تىجارەت ھالىتى مۇۋەپپەقىيەتلىك يېڭىلاندى",
    noPermission: "كەچۈرۈڭ، سىزنىڭ بۇ رايونغا ھوقۇقىڭىز يەتمەيدۇ",
  },
  order: {
    eat: "مۇشۇ يەردە يەيدۇ",
    take: "ئەكىتىدۇ",
    print: "پىرىنتېردا بېسىش",
    new: "يېڭى زاكاس بايقالدى",
    newDesc: "يېڭى زاكاس بايقالدى، تىېز بىر تەرەپ قىلىڭ",
    printView: {
      price: "يەككە باھاسى",
      total: "جەمئىي",
      amount: "سانى",
    },
    isFromBB: "بۇلۇت بازاردىن كەلگەن",
    isDouyin: "دوۋيىن",
    payTypes: {
      all: "ھەممىسى",
      wechat: "ئۈندىدار",
      cash: "نەق پۇل",
      balance: "قالدۇق",
    },
    orderTypes: {
      all: "ھەممىسى",
      valid: "ئۈنۈملۈك",
      invalid: "قالدۇرۇلغان",
      unsend: "مال سېلىنمىغان",
      waiting: "بىر تەرەپ قىلمىغان",
      sent: "مال سېلىنغان",
      delivered: "تاپشۇرىۋالغان",
      completed: "تاماملانغان",
      applied: "ئىلتىماس قىلىنغان",
    },
    refundTypes: {
      all: "ھەممىسى",
      waiting: "بىر تەرەپ قىلمىغان",
      accepted: "تەستىقلانغان",
      refunding: "قايتۇرىلىۋاتقان",
      refunded: "قايتۇرۇلغان",
      refused: "رەت قىلىنغان",
      failed: "بىر تەرەپ قىلمىغان",
    },
    sortTypes: {
      created_at_desc: "يېڭىسى ئالدىدا",
      created_at_asc: "كونىسى ئالدىدا",
      sequence: "زاكاز تەرتىپى",
    },
    loadingData: "زاكاز تىزىملىكى يۈكلىنىۋاتىدۇ ...",
    printingOrder: "زاكاز ئۇرۇلىۋاتىدۇ...",
    updatingData: "زاكاز تىزىملىكى يېڭىلىنىۋاتىدۇ ...",
    payTypesTitle: "پۇل تۆلەش شەكلى",
    finishProfitSharing: "زاكازنى ئاخىرلاشتۇرۇش",
    orderTypesTitle: `زاكاز         ھالىتى`,
    sortTypesTitle: "زاكاز     تىزىلىشى",
    rangeTimeTitle: "زاكاز          ۋاقتى",
    ordersTitle: "زاكازلار تىزىملىكى",
    ordersTitleCount: "جەمئىي %{total} تال زاكاز",
    loadingOrder: "زاكازلار تىزىملىكى يۈكلىنىۋاتىدۇ ...",
    loadingOrderEmpty: "مۇناسىۋەتلىك زاكاز تېپىلمىدى",
    todaysTitle: " زاكاز ئەھۋالى",
    detailStatisticsTitle: " مال ئەھۋالى",
    todaysLoading: "ئۇچۇر ئېلىۋاتىدۇ ...",
    todaysEmpty: "ھازىرچە ئۇچۇر يوق",
    todaysCount: "زاكاز : %{orderCount} تال",
    todaysTotal: "زاكاز ئۇمۇمي سوممىسى : %{totalPrice} يۈەن",
    productInfo: "مەھسۇلات ئۇچۇرى",
    productInfoCollapse: "يىغىش",
    productInfoExpand: "ئېچىش",
    note: "خېرىدار ئەسكەرتىشى：",
    noteUser: "[تەلەپ - تەغدىم] %{note}؛",
    noteNo: "ئالاھىدە تەلەپ يوق",
    tablewareCount: "[چوكا - قوشۇق] %{count}؛",
    tablewareNo: "ئۆزى تەييارلايدۇ",
    tablewarePeople: "%{count} ئادەم",
    packingFee: "ئوراش ھەققى",
    giftLabel: "سوۋغات:",
    shippingFee: "توشۇش ھەققى",
    subTotal: "جەمئىي",
    merchantExpence: "دۇكاندار پائالىيەت چىقىمى",
    merchantExpenceDisocunt: "ئېتبار مەھسۇلات سوممىسى %{price} يۈەن",
    merchantExpenceCoupon: "ئېتىبار كارتا سوممىسى %{price} يۈەن",
    platformExpence: "سۇپا پائالىيەت چىقىمى",
    platformExpenceDisocunt: "ئېتبار مەھسۇلات سوممىسى %{price} يۈەن",
    platformExpenceCoupon: "ئېتىبار كارتا سوممىسى %{price} يۈەن",
    platformIncome: "بۇلۇت بازار سۇپا چىقىمى",
    platformRate: "بۇلۇت بازار سۇپا نىسپىتى %%{rate}",
    merchantIncome: "بۇ زاكازدىن كىرىدىغان كىرىم",
    userPayed: "خېرىدار ئەمەلىي تۆلىگەن پۇل :",
    userPayedProductFee: "  تۆلىگەن مال پۇلى :",
    userPayedShipPriceFee: "  تۆلىگەن كىرا پۇلى :",
    paymentFee: "ئۈندىدار پۇل تۆلەش ھەققى",
    statusPayed: "(تۆلەپ بولغان)",
    orderTime: "زاكاز ۋاقتى：",
    orderNo: "زاكاز نومۇرى：",
    jdOrderNo: "جىڭدۇڭ زاكاز نومۇرى：",
    bbOrderNo: "زاكاز ID：",
    orderShipNo: "يوللانما نومۇرى：",
    copyShipNo: "يوللانما نومۇرىنى كۆچۈرۈش",
    copyShipNoSuccess: "مۇۋەپپەقىيەتلىك كۆچۈرۈلدى",
    copyShipNoFailed: "كۆچۈرۈش مەغلۇپ بولدى",
    accept: "قۇبۇل قىلىش",
    acceptHint: "بۇ زاكازنى راستىنلا قۇبۇل قىلامسىز ؟",
    accepting: "زاكاز قۇبۇل قىلىنىۋاتىدۇ ...",
    cancel: "رەت قىلىش",
    cancelHint: "بۇ زاكازنى راستىنلا رەت قىلامسىز ؟",
    canceling: "زاكاز رەت قىلىنىۋاتىدۇ ...",
    send: "مال سېلىش",
    prepared: "تاماق (مال) تەييارلاندى",
    delivered: "يەتكۈزۈلدى",
    reject: "رەت قىلىش",
    sendTitle: "زاكاز مال سېلىش",
    discountTitle: "ئېتىبار باھا",
    sendTrackName: "يوللانما  ئىسمى",
    sendTrackDate: "مال سالغان ۋاقىت",
    sendTrackReciet: "تالون",
    sendTrackNameHint: "يوللانما ئىسمىنى تاللاڭ",
    sendTrackReason: "ئىزاھات",
    sendTrackNumber: "يوللانما نومۇرى",
    sendTrackNumberHint: "يوللانما نومۇرىنى كىرگۈزۈڭ",
    sendShipWeight: "ئېغىرلىقى",
    sendShipWeightUnit: "كىلوگىرام",
    sendShipWeightHint: "يوللانما ئغىرلىقىنى كىرگۈزۈڭ",
    sendShipPrice: "توشۇش ھەققى",
    sendShipReceipt: "تالون",
    sendShipPriceUnit: "يۈەن",
    sendShipPriceHint: "توشۇش ھەققىنى كىرگۈزۈڭ",
    sending: "مال سېلىش ئۇچۇرى يوللىنىۋاتىدۇ ...",
    sendChange: "يوللانما ئۆزگەرتىش",
    sendChangeTitle: "زاكاز يوللانما ئۇچۇرىنى ئۆزگەرتىش",
    sendChanging: "زاكاز يوللانما ئۇچۇرى يېڭىلىنىۋاتىدۇ ...",
    user: "زاكاز چۈشۈرگۈچى：(%{user})",
    kuaidi100Title: "تېز يوللانما تەكشۈرۈش",
    addForm: "قوشۇمەن",
    deleteForm: "ئۆچۈرىمەن",
    importErrorCheck: "خاتالىق ئۇچۇرىنى كۆرىمەن",
    calculate: "پايدا ئايرىش",
    parentCommission: "يۈەن %{price} ئاتا ۋاكالەتچى پايدىسى",
    childCommission: "يۈەن %{price} ۋاكالەتچى پايدىسى",
    refund: "پۇل قايتۇرۇش",
    refundCount: "قايتۇرىدىغان سانى:",
    refundMax: "ئەڭ كۆپ بولغاندا %{max}نى قايتۇرغىلى بولىدۇ",
    refundConfirm: "پۇل قايتۇرامسىز؟",
    refundAmount: "قايتۇرغان سانى %{amount}",
    refundPrice: "قايتۇرغان پۇلى %{price} يۈەن",
    atLeastRefund: "پۇل قايتۇرىدىغان مەھسۇلاتنى تاللاڭ",
    refundReasonWarning: "پۇل قايتۇرۇش سەۋەبىنى يېزىڭ",
    refundReason: "پۇل قايتۇرۇش سەۋەبى نىمە؟",
    refundCheck: "پۇل قايتۇرۇلىشىنى تەكشۈرۈش",
    refundAccept: "پۇل قايتۇرۇش",
    refundReject: "رەت قىلىش",
    refundAcceptAfterReject: "مالنى رەت قىلغاندا پۇل قايتۇرۇش",
    refundAcceptAfterReturn: "مال قايتۇرغاندا پۇل قايتۇرۇش",
    status: "زاكاز ھالىتى",
  },
  shows: {
    type: {
      all: "ھەممىسى",
      text: "تېكىست",
      image: "رەسىم",
      video: "فىلىم",
      audio: "ئاۋاز",
    },
    status: {
      all: "ھەممىسى",
      valid: "تەستىقلانغان",
      invalid: "تەستىقلانمىغان",
      draft: "يوللانمىغان",
      rejected: "رەت قىلىنغان",
      active: "تەستىقلانغان",
      inactive: "تەستىقلانمىغان",
    },
    sortTypes: {
      created_at_desc: "يېڭى يوللانغىنى",
      created_at_asc: "بۇرۇن يوللانغىنى",
    },
    noTags: "ماركا يوق",
    mediaTypesTitle: "ۋاستە",
    orderTypesTitle: "ھالىتى",
    sortTypesTitle: "تەرتىپى",
    showsTitle: "مەزمۇنلار",
    loadingShow: "ئۇچۇر كېلىۋاتىدۇ",
    loadingShowEmpty: "مەزمۇن يوق",
    showsTitleCount: "جەمئى %{total} پارچە مەزمۇن بار",
    deleteConfirm: "راستىنلا ئۆچۈرىمەن",
    categories: "تۈرلەر",
    add: "يېڭى مەزمۇن قوشۇش",
  },
  showsEdit: {
    videoCover: "مۇقاۋىسى",
    headerTitle: "يېڭى مەزمۇن",
    headerTitleEdit: "تەھرىرلەش",
    creatingShowSuccess: "مۇۋەپپەقىيەتلىك قېتىلدى",
    updatingProductSuccess: "مۇۋەپپەقىيەتلىك يېڭىلاندى",
    selectProduct: "مەھسۇلات تاللاڭ",
    bindProduct: "مەھسۇلاتقا باغلاش",
    catLabel: "تۈرلەر",
    nameLabel: "مەزمۇنى",
    nameHint: "مەزمۇنىنى تولدۇرۇڭ",
    nameRequired: "قۇرۇق قالمىسۇن",
    imagesLabel: "رەسىم",
    tagLabel: "ماركا",
    tagHint: "ماركا تاللاڭ",
    statusLabel: "ۋاستە",
    languageLabel: "تىلى",
    productLabel: "ماركا",
    status: {
      image: "رەسىم",
      video: "فىلىم",
      text: "تېكىست",
    },
    orderLabel: "تەرتىپى",
    addTag: "ماركا قوشۇش",
    orderIdLabel: "زاكاز نومۇرى",
  },
  product: {
    filter: {
      all: "ھەممىسى",
      onShelf: "سېتىلىۋاتقان",
      offShelf: "توختىتىلغان",
      discounted: "باھاسى چۈشۈرۈلگەن",
      order_desc: "پايدا نىسپىتى يۇقىرى",
      order_asc: "پايدا نىسپىتى تۆۋەن",
      price_start: "تۆۋەن",
      price_end: "يۇقىرى",
      quantity_desc: "ئامبار سانى جىق",
      quantity_asc: "ئامبار سانى ئاز",
    },
    search: {
      product_no: "مەھسۇلات نومۇرى",
      name: "مەھسۇلات نامى",
    },
    discount: {
      title: "مەھسۇلاتنى پائالىيەت قىلىش",
      priceLabel: "ئېتىبار باھا",
      discountedPriceHint: "ئېتىبار باھانى كىرگۈزۈڭ",
      discounting: "ئېتىبار باھا ئۇچۇرى يوللىنىۋاتىدۇ ...",
    },
    loadingCatsData: "تۈر ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingProductsData: "مەھسۇلات تىزىملىك ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    headerCreate: "مەھسۇلات قوشۇش",
    headerSeatchHint: "",
    catsTitle: "تۈر تىزىملىكى",
    catsManage: "تۈر باشقۇرۇش",
    catsLoading: "تۈر ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    catsNull: "ھازىرچە تۈر ئۇچۇرى يوق",
    catsEmpty: "تېخى تۈر قوشۇلمىغان",
    selectAll: "ھەممىنى تاللاش",
    batchOnShelf: "ئوپچە چىقىرىش",
    batchOffShelf: "ئوپچە توختۇتۇش",
    productsEmpty: "ھازىرچە مۇناسىۋەتلىك مەھسۇلات ئۇچۇرى يوق",
    catsModalTitle: "تۈر باشقۇرۇش",
    catsModalOkText: "مۇقىملاش",
    catsModalCancelText: "قالدۇرۇش",
    catsModalAvailable: "تاللانمىغان تۈرلەر",
    catsModalSelected: "دۇكان تۈرلىرى",
    catsModalAdd: "قوشۇش",
    catsModalRemove: "چىقىرۋېتىش",
    catsModalInclude: " (%{count} تال مەھسۇلات بار)",
    catsModalIncludeWarning: "مەھسۇلات بار تۈرنى چىقىرۋەتكىلى بولمايدۇ",
    updatingProduct: "مەھسۇلات ئۇچۇرنى يېڭىلاۋاتىدۇ ...",
    updatingProductSuccess:
      "مەھسۇلات ئۇچۇرى مۇۋەپپەقىيەتلىك يېڭىلاندى، يېڭى ئۇچۇر يۈكلىنىۋاتىدۇ ...",
    priceRangeAlert: "ئەڭ تۆۋەن باھا ئەڭ يۇقىرى باھادىن يۇقىرىكەن",
    productItem: {
      offShelf: "توختىتىلغان",
      monthySale: "سېتىلىشى %{count}",
      likes: "ياقتۇرۇلىشى %{count}",
      quantity: "سانى",
      quantityLimitless: "تۈگىمەس",
      quantityZero: "تۈگىدى",
      actionUp: "چىقىرىش",
      actionDown: "توختۇتۇش",
      actionConfirm: "مۇقىملاش",
      actionDelete: "ئۆچۈرۈش",
      actionEdit: "تەھرىرلەش",
      actionCancel: "قالدۇرۇش",
      oneKeyCopy: "نەق مەيدان ئادىرىسى",
      skuImage: "/assets/images/product-item-sku-ug.png",
      actionClone: "كۆپەيتىش",
    },
    cats: {
      actions: "مەشغۇلات",
      addChild: "بالا سەھىپە قوشۇش",
      edit: "تەھرىرلەش",
      delete: "ئ‍ۆچۈرۈش",
      search: "ئىزدىمەكچى بولغان سەھىپە نامىنى كىرگۈزۈڭ",
      add: "سەھىپە قوشۇش",
      deleteWarning: "بۇ سەھىپىنى ئۆچۈرەمسىز؟",
      catWarning: "سەھىپە يوق ئىكەن",
      catEditWarning: "سەھىپە يوق ئىكەن",
      zhName: "خەنزۇچە سەھىپە نامى",
      ugName: "ئۇيغۇرچە سەھىپە نامى",
      save: "ساقلاش",
      cancel: "قالدۇرۇش",
    },
  },
  productEdit: {
    status: {
      onShelf: "چىقىرىش",
      offShelf: "توختۇتۇش",
    },
    suggest: {
      on: "تەۋسىيەلەش",
      off: "تەۋسىيەدىن قالدۇرۇش",
    },
    headerTitle: "مەھسۇلات قوشۇش",
    headerTitleEdit: "مەھسۇلات تەھرىرلەش",
    video: "فىلىم",
    headerBack: "قايتىش",
    catLabel: "مەھسۇلات تۈرى",
    catRequired: "مەھسۇلات تۈرى قۇرۇق بولمىسۇن",
    nameLabel: "مەھسۇلات نامى (خەنزۇچە)",
    nameHint: "مەھسۇلات خەنزۇچە نامىنى كىرگۈزۈڭ",
    numberLabel: "مال نومۇرى",
    numberHint: "مال نومۇرىنى كىرگۈزۈڭ",
    numberRequired: "مال نومۇرى قۇرۇق بولمىسۇن",
    valid_date: "ساقلاش مۇددىتى",
    validHint: "ساقلاش مۇددىتىنى كىرگۈزۈڭ",
    manufactured_date: "ئىشلەپچىقىرىش ۋاقتى",
    recommended_price: "پارچە سېتىش باھاسى",
    recommendedHint: "پارچە سېتىش باھاسىنى كىرگۈزۈڭ",
    basic_sales: "مەۋھۈم سېتىلىشى",
    basicHint: "مەۋھۇم سېتىلىشىنى كىرگۈزۈڭ",
    barcodeLabel: "تاياقچە كود",
    barcodeHint: "تاياقچە كودنى كىرگۈزۈڭ",
    nameRequired: "مەھسۇلات خەنزۇچە نامى قۇرۇق بولمىسۇن",
    nameUgLabel: "مەھسۇلات نامى (ئۇيغۇرچە)",
    nameUgHint: "مەھسۇلات ئۇيغۇرچە نامىنى كىرگۈزۈڭ",
    imagesLabel: "مەھسۇلات رەسىمى",
    zhImagesLabel: "تۆت كاتەك رەسىم(خەنزۇچە)",
    ugImagesLabel: "تۆت كاتەك رەسىم(ئۇيغۇرچە)",
    imagesUpload: "قوشۇش",
    imagesRequired: "مەھسۇلات رەسىمى ئەڭ ئاز بولغاندا بىر تال بولسۇن",
    descLabel: "چۈشەندۈرلىشى (خەنزۇچە)",
    detailLabel: "تەپسىلاتى",
    descHint: "مەھسۇلات خەنزۇچە چۈشەندۈرلىشىنى يېزىڭ، 400 خەت بىلەن چەكلىنىدۇ",
    descUgLabel: "چۈشەندۈرلىشى (ئۇيغۇرچە)",
    detailDescLabel: "تەپسىلىي چۈشەندۈرۈلىشى (خەنزۇچە)",
    detailDescLabelHint:
      "بۇ مەھسۇلاتنىڭ خالتا، قۇتا ۋە يەشىك ئۇچۇرىنى تولدۇرۇڭ",
    detailUgDescLabelHint:
      "بۇ مەھسۇلاتنىڭ خالتا، قۇتا ۋە يەشىك ئۇچۇرىنى تولدۇرۇڭ (ئۇيغۇرچە)",
    detailDescUgLabel: "تەپسىلىي چۈشەندۈرۈلىشى (ئۇيغۇرچە)",
    descUgHint:
      "مەھسۇلات ئۇيغۇرچە چۈشەندۈرلىشىنى يېزىڭ، 400 خەت بىلەن چەكلىنىدۇ",
    standardLabel: "مەھسۇلات باھاسى: ",
    propertyLabel: "مەھسۇلات ئۆلچىمى",
    inPriceLabel: "كىرىش باھاسى: ",
    shipPriceLabel: "كىرا ھەققى: ",
    maxBuyLimitLabel: "ئەڭ كۆپ سېتىۋېلىش سانى: ",
    minBuyLimitLabel: "ئەڭ ئاز سېتىۋېلىش سانى: ",
    inPriceLabelShort: "كىرىش:",
    outPriceLabel: "سېتىش باھاسى",
    outPriceLabelShort: "سېتىش: ",
    commission: "پايدا پىرسەنتى",
    parentCommission: "باش پايدا پىرسەنتى",
    quantityLabel: "سانى: ",
    packPriceLabel: "قاچىلاش ھەققى",
    packCountLabel: "قاچىلاش سانى",
    standardRequired: "قۇرۇق بولمىسۇن",
    statusLabel: "مەھسۇلات ھالىتى",
    orderLabel: "مەھسۇلات تەرتىپى",
    suggestLabel: "تەۋسىيە ھالىتى",
    skuImage: "/assets/images/product-edit-sku-ug.png",
    saveAndBack: "ساقلاپ چېكىنىش",
    save: "ساقلاش",
    saveAndContinue: "ساقلاپ داۋاملىق قوشۇش",
    cancel: "قالدۇرۇش",
    skuWarning: "سۇپا مەھسۇلاتىنى تەھرىرلىگىلى بولمايدۇ",
    creatingProduct: "مەھسۇلات قوشۇلىۋاتىدۇ ...",
    creatingProductSuccess: "مەھسۇلات قوشۇش مۇۋەپپەقىقيەتلىك بولدى",
    updatingProduct: "مەھسۇلات ئۇچۇرى يېڭىلىنىۋاتىدۇ ...",
    updatingProductSuccess: "مەھسۇلات ئۇچۇرى مۇۋەپپەقىيەتلىك يېڭىلاندى",
    uploadingImages: "رەسىم يوللىنىۋاتىدۇ ...",
    uploadImageTypeWarning:
      "رەسىم ھۆججەت شەكلى چوقۇم .jpg/.jpeg/.png بولۇشى كېرەك !",
    uploadImageSizeWarning:
      "رەسىم ھۆججەت چوڭ-كىچىكلىكى 1MB دىن ئېشىپ كەتمىسۇن !",
    uploadVideoSizeWarning:
      "فىلىم ھۆججەت چوڭ-كىچىكلىكى 3MB دىن ئېشىپ كەتمىسۇن !",
    uploadNavImageSizeWarning:
      "رەسىم ھۆججەت چوڭ-كىچىكلىكى 200KB دىن ئېشىپ كەتمىسۇن !",
    delete: "بۇ مەھسۇلاتنى ئۆچۈرەمسىز؟",
    translate: "تەرجىمە",
    isAgentLevelProduct: "ۋاكالەتچى دەرىجە",
    refetchImgs: "رەسىمنى يېڭىلاش",
    refetchDesc: "تەپسىلاتنى يېڭىلاش",
    restorePrice: "ئەسلىگە قايتۇرۇش",
    priceDiscount: "باھاسىنى چۈشۈرۈش",
    basicInfo: "ئاساسى ئۇچۇرلىرى",
    priceInfo: "باھا ئۇچۇرلىرى",
    otherInfo: "باشقا ئۇچۇرلار",
    commissionInfo: "پايدا ئايرىش",
    isFreeShipping: "كىرا ھەقسىزمۇ",
    freeShipping: "كىرا يوق",
    notFreeShipping: "كىرا بار",
    imagesZhLabel: "رامكا رەسىم (خەنزۇچە)",
    imagesUgLabel: "رامكا رەسىم (ئۇيغۇرچە)",
    douyinOrder: "دوۋيىن",
  },
  review: {
    status: {
      all: "ھەممىسى",
      reviewed: "جاۋاپ قايتۇرۇلغان",
      unReviewed: "جاۋاپ قايتۇرۇلمىغان",
    },
    satisfaction: {
      all: "ھەممىسى",
      best: "ياخشى",
      normal: "نورمال",
      worst: "ناچار",
    },
    content: {
      all: "ھەممىسى",
      noContent: "مەزمۇن بار",
    },
    loadingData: "باھالار تىزىملىك ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingDataEpmty: "مۇناسىۋەتلىك باھالار ئۇچۇرى يوق",
    updatingData: "باھالار تىزىملىك ئۇچۇرى يېڭىلىنىۋاتىدۇ ...",
    headerTitle: "يېقىنقى 30 كۈندىكى باھالار ئىستاتىستىكىسى：",
    headerMerchant: "دۇكان باھاسى",
    headerClient: "خېرىدار مەمنۇنلىقى",
    filterStatus: "باھا      ھالىتى",
    filterSatisfaction: "باھا دەرىجىسى",
    filterContent: "باھا   مەزمۇنى",
    filterDate: "باھا       ۋاقتى",
    listTitle: "مۇناسىۋەتلىك باھا: %{count} تال",
    replyValidWarning: "جاۋاپ مەزمۇنىنى كىرگۈزۈڭ",
    replyLengthWarning: "جاۋاپ مەزمۇنى 300 خەتتىن ئېشىپ كەتمىسۇن",
    replyCommitting: "جاۋاپ يوللىنىۋاتىدۇ ...",
    replyCommittingSuccess:
      "جاۋاپ مۇۋەپپەقىيەتلىك يوللاندى، ئۇچۇر يېڭىلىنىۋاتىدۇ ...",
    reviewItem: {
      score5: "ئېسىل",
      score4: "ياخشى",
      score3: "نورمال",
      score2: "بولىدۇ",
      score1: "ناچار",
      merchantScore: "دۇكانچى باھاسى %{score} يۇلتۇز：%{scoreText}",
      merchantText: "دۇكانچى",
      deliveryText: "يەتكۈزۈش",
      ovewrallText: "ئۇمۇمىي",
      overallOk: "رازى بولدۇم",
      overallNotOk: "رازى بولمۇدۇم",
      noContent: "مەزمۇن يوق",
      noImage: "رەسىم يوق",
      replyText: "جاۋاپ：",
      noReply: "جاۋاپ قايتۇرۇلمىغان",
      actionReply: "جاۋاپ قايتۇرۇش",
      replyHint:
        "مەزمۇن 300 خەتتىن ئېشىپ كەتمىسۇن، ئەگەردە يامان مەقسەتتە جاۋاپ قايتۇرۇپ بايقىلىپ قالسىڭىز" +
        " مەسئۇلىيىتىڭىز ئېغىر سۈرۈشتە قىلىنىدۇ",
      replyConfirm: "مۇقىملاشتۇرۇش",
      replyCancel: "قالدۇرۇش",
      detailLabel: "زاكاز تەپسىلاتى",
    },
  },
  financeHome: {
    loadingData: "ھېسابات ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingEmpty: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق",
    headerTitle: "ھېسابات ئۇچۇرى",
    headerAllBills: "ھەممە ھېسابات",
    listTitle: "بوغۇلغان ھېسابات",
    listTitleUnsettled: "بوغۇلمىغان ھېسابات",
    listHeaderDate: "چېسىلا",
    listHeaderSum: "ھېسابات سوممىسى",
    listHeaderActions: "مەشغۇلات",
    listHeaderDetails: "ھېسابات ئۇچۇرى",
    listItemActionDetails: "تەپسىلاتى",
    listItemActionDownload: "چۈشۈرۈش",
    listGroupTime: "%{start_date} دىن %{end_date} گىچە بولغان ھېسابات",
    listGroupInfo: "ھېساپ بوغۇلۇپ بولغان >",
    listGroupInfoUnsettled: "پىلاندا %{sign_date} كۈنى ھېسابات قىلىنىدۇ",
  },
  financeCheck: {
    selectTime: "ۋاقىت تاللاڭ",
    allTime: "ھەممە ۋاقىت",
    loadingSettledData: "بوغۇلغان ھېسابات ئۇچۇرى يۈكلىنىۋاتىدۇ...",
    loadingUnsettledData: "بوغۇلمىغان ھېسابات ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
  },
  financeAccount: {
    times: {
      month: "30 كۈن",
      season: "90 كۈن",
      custom: "ئىختىيارى",
    },
    settlementTypes: {
      cycle: "دەۋري ھېسابات قىلىش",
    },
    loadingData: "ھېسابات ئاكونت ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingEmpty: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق",
    ballanceInfoTitle: "ھېسابات ئۇچۇرى",
    ballanceInfoCycle: "ھېسابات دەۋري",
    ballanceInfoCycleValue: "%{day} كۈندە بىر",
    ballanceInfoCycleType: "ھېسابات قىلىش شەكلى",
    ballanceInfoMin: "ئەڭ تۆۋەن ھېسابات سوممىسى",
    ballanceInfoValue: "%{amount} يۈەن",
    ballanceInfoContact: "مالىيە ئالاقىلاشقۇچى",
    ballanceInfoContactPhone: "ئالاقىلاشقۇچى نومۇرى",
    bankInfoTitle: "بانكا ئۇچۇرى",
    bankInfoNameFull: "بانكا نامى(تولۇق نامى)",
    bankInfoBankTypeCompany: "شىركەت",
    bankInfoBankTypePrivate: "شەخسىي",
    bankInfoBranchId: "بىرلەشمە بانكا نومۇرى",
    bankInfoType: "ئاكونت شەكلىي",
    bankInfoAddressCode: "رايون نومۇرى",
    bankInfoName: "بانكا نامى(قىسقا نامى)",
    bankInfoAccount: "بانكا كارتا نومۇرى",
    bankInfoAccountName: "بانكا ئاكونت نامى",
    organizationType: "تۈرى",
    organizationTypeMicro: "مىكرو تىجارەتچى",
    organizationTypePersonal: "شەخسى",
    organizationTypeIndividual: "شەخسىي تىجارەتچى",
    organizationTypeEnterprise: "شىركەت",
    organizationTypeGovernment: "ھۆكۈمەت ئورنى",
    organizationTypeOther: "باشقا ئورگانلار",
    contactType: "ئالاقىلاشقۇچى شەكلى",
    contactTypeLegal: "قانۇن ۋەكىلى",
    contactTypePersonInCharge: "مەسئۇل خادىم",
    contact_email: "ئىلخەت",
    listTitle: "ھېسابات تالون خاتىرسى",
    listFilterTime: "ۋاقىت تاللاڭ",
    listHeaderDate: "چېسىلا",
    listHeaderName: "ھېسابات نامى",
    listHeaderAmount: "ھېسابات سوممىسى",
    listHeaderStatus: "ھالىتى",
    listHeaderAction: "مەشغۇلات",
    listItemName: "%{start_date} دىن %{end_date} غىچە ھېسابات",
    checkoutDateLabel: "%{checkout_date}ھېسابات",
    listItemAmount: "%{amount} يۈەن",
    listItemStatusSuccess: "تامام بولدى",
    listItemActionDetails: "تەپسىلاتى",
    basicInfo: "ئاساسى ئۇچۇرى",
    merchantAccountInfo: "بانكا ئۇچۇرى",
    merchantInfo: "سودا سانائەت ئۇچۇرى",
    licences: "كىنىشكە",
    licenceNumber: "كىنىشكە نومۇرى",
    licenceName: "كىنىشكىدىكى ئىسمى",
    licenceImage: "كىنىشكە رەسىمى",
    licenceLegal: "قانۇن ۋەكىلى",
    licenceIdCardFront: "كىملىك ئالدى",
    licenceIdCardBack: "كىملىك كەينى",
    licenceIdName: "كىملىكتىكى ئىسمى",
    licenceIdNumber: "كىملىك نومۇرى",
    licenceIdAddress: "كىملىك ئادرېسى",
    licenceIdStartDate: "كىملىك باشلىنىش ۋاقتى",
    licenceIdEndDate: "كىملىك ئاخىرلىشىش ۋاقتى",
    idLongTerm: "ئۇزۇن ۋاقىت",
    merchantAbbr: "قىسقىچە ئىسمى",
    customerNumber: "مۇلازىمەت نومۇرى",
    logo: "دۇكان باش رەسىمى",
  },
  financeDownload: {
    tabs: {
      generate: "ھېسابات ۋاقتىنى تاللاش",
      history: "ھېسابات ھاسىللاش تارىخى",
    },
    times: {
      month: "1 كۈن",
      season: "7 كۈن",
      custom: "ئىختىيارى",
    },
    loadingData: "ھېسابات ھاسىللاش تارىخى ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingEmpty: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق",
    generateFilterTime: "چېسلانى تاللاڭ",
    generateActionGenerate: "ھېسابات ھاسىللاش",
    historyHeaderNo: "تەرتىپى",
    historyHeaderName: "ھۆججەت نامى",
    historyHeaderStatus: "ھالىتى",
    historyHeaderActions: "مەشغۇلات",
    listItemName: "%{start_date} دىن %{end_date} غىچە ھېسابات",
    checkoutDateLabel: "%{checkout_date} ھېسابات",
    historyItemStatusSuccess: "ئوڭۇشلۇق بولدى",
    historyItemStatusGenerating: "ھاسىىللىنىۋاتىدۇ ...",
    historyItemStatusFailed: "مەغلۇپ بولدى",
    historyItemActionDownload: "چۈشۈرۈش",
    historyItemActionDelete: "ئۆچۈرۈش",
    historyItemActionDeleteWarning: "بۇ ھېساباتنى راستىنلا ئۆچۈرەمسىز ؟",
    historyItemActionDeleteWarningOk: "ھەئە",
    historyItemActionDeleteWarningCancel: "بولدى",
    generating: "ھېسابات ھاسىللىنىۋاتىدۇ ...",
    generatingWarning:
      "ھسېسابات ھاسىللىنىۋاتىدۇ، بىر ئازدىن كىيىن مەشغۇلات قىلىڭ !",
    deleting: "ھېسابات ئۆچۈرلىۋاتىدۇ ...",
    generateSuccess:
      "ھېسابات مۇۋەپپەقىيەتلىك يوللاندى، ئۇچۇر يېڭىلىنىۋاتىدۇ ...",
    updatingList: "ئۇچۇر يېڭىلىنىۋاتىدۇ ...",
    deleteSuccess:
      "ھېسابات مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى، ئۇچۇر يېڭىلىنىۋاتىدۇ ...",
    range: "ئەڭ كۆپ بولغاندا 15 كۈنلۈكنى چىقىرالايسىز",
  },
  orderDownload: {
    tabs: {
      generate: "چېسلانى تاللاڭ",
      history: "تارىخى",
    },
    generateActionGenerate: "جەدۋەل چىقىرىش",
    range: "ئەڭ كۆپ بولغاندا ئىككى كۈنلۈكنى چىقىرالايسىز",
    date: "چېسلاسى",
    orderCount: "زاكاز سانى",
  },

  financeBillDetail: {
    loadingData: "ھېسابات تالون تەپسىلات ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingEmpty: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق",
    headerBack: "قايتىش",
    headerTitle: "ھېسابات تالون تەپسىلاتى",
    billAmount: "ھېسابات سوممىسى",
    billAmountValue: "%{amount} يۈەن",
    billAccount: "ھېسابات ئاكونت",
    billType: "ھېسابات شەكلى",
    billTime: "ھېسابات ۋاقتى",
  },
  financeCheckDaily: {
    tabs: {
      order: {
        name: "زاكاز تۈردىكىلەر",
        tabs: {
          all: "ھەممىسى",
          normal: "نورمال زاكاز",
          refund: "پۇل قايتۇرۇلغان",
        },
      },
      other: {
        name: "باشقا تۈردىكىلەر",
        tabs: {
          all: "ھەممىسى",
          ship_fee: "توشۇش ھەققى",
          bad_food: "بۇزۇلغان تاماق تۆلىمى",
          serve_return: "قايتۇرۇلغان مۇلازىمەت ھەققى",
          part_refund: "قىسمەن قايتۇرۇلغان پۇل",
          refund_neutralize: "قىسمەن توغۇرلاش ھەققى",
        },
      },
    },
    loadingData: "كۈنلۈك زاكاز تەپسىلات ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    loadingEmpty: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق",
    headerBack: "قايتىش",
    headerDate: "%{date} كۈندىكى ھېسابات",
    headerInfo: " كۈنى ھېساپ بوغۇلۇپ بولغان",
    headerInfoUnsettled: "پىلاندا %{sign_date} كۈنى ھېسابات قىلىنىدۇ",
    listUnit: "بىرلىكى：يۈەن",
    listHeaderOrderDate: "چېسىلا & تەرتىپى",
    listHeaderOrderType: "شەكلى",
    listHeaderOrderPrice: "ئەسلى باھاسى",
    listHeaderOrderPacking: "قاچىلاش ھەققى",
    listHeaderOrderEvent: "پائالىيەت ھەققى",
    listHeaderOrderPlatform: "سۇپا ھەققى",
    listHeaderOrderShip: "توشۇش ھەققى",
    listHeaderOrderDonate: "ئىئانە ھەققى",
    listHeaderOrderTotal: "جەمئىي",
    listHeaderOrderAction: "مەشغۇلات",
    listHeaderOtherDate: "چېسىلا",
    listHeaderOtherType: "شەكلى",
    listHeaderOtherDetails: "تەپسىلاتى",
    listHeaderOtherTotal: "جەمئىي",
    listHeaderOtherAction: "مەشغۇلات",
    listItemActionDetail: "تەپسىلاتى",
    listFooterTotal: "جەمئىي (%{order_count} تال)",
  },
  financeOrderDetail: {
    headerBack: "قايتىش",
    headerTitle: "زاكاز تەپسىلاتى",
    detailProducts: "مەھسۇلاتلار",
    detailMerchant: "پائالىيەت ھەققى",
    detailPaymentFee: "ئۈندىدار ھەققى",
    detailPlatform: "سۇپا ھەققى",
    detailShip: "توشۇش ھەققى",
    detailDonation: "ئىئانە ھەققى",
    detailTotal: "ئۇرۇلغان ھېسابات",
    footerSequence: "زاكاز تەرتىپى：#%{sequence}",
    footerNo: "زاكاز نومۇرى：%{no}",
    footerTime: "زاكاز ۋاقتى：%{time}",
    footerTimeFinish: "تاماملانغان ۋاقتى：%{time}",
  },
  proxy: {
    loadingData: "ۋاكالەتچىلەر ئۇچۇرى يۈكلىنىۋاتىدۇ ...",
    updatingData: "ۋاكالەتچىلەر ئۇچۇرى يېڭىلىنىۋاتىدۇ ...",
    searchFromParent: "يېتەكچىسىدىن ئىزدەش",
    filterMobile: "تېلېفون       نومۇرى",
    filterMobileHint: "ۋاكالەتچى تېلېفون نومۇرىنى ياكى ئىسمىنى كىرگۈزۈڭ",
    filterLevel: "ۋاكالەتچى دەرىجىسى",
    filterOwner: "ۋاكالەتچىسى بارمۇ يوق",
    filterStatus: "تەستىق ھالىتى",
    addProxy: "ۋاكالەتچى قوشۇش",
    listTitle: "مۇناسىۋەتلىك ۋاكالەتچى %{count} تال",
    listItemLevel: "دەرىجىسى",
    listItemMobile: "تېلېفونى",
    listItemPercent: "پىرسەنتى",
    listItemParent: "يۇقىرىسى",
    listItemParentNone: "يوق",
    listItemEdit: "تەھرىرلەش",
    listItemDelete: "ئۆچۈرۈش",
    listNoData: "ۋاقتىنچە مۇناسىۋەتلىك ئۇچۇر يوق",
    deleteHint: "بۇ ۋاكالەتچىنى راستىنلا ئۆچۈرەمسىز ؟",
    deleting: "ۋاكالەتچىنى ئۆچۈرۋاتىدۇ ...",
    updating: "ۋاكالەتچىنى يېڭىلاۋاتىدۇ ...",
    adding: "ۋاكالەتچى قۇشۇلۇۋاتىدۇ ...",
    editMobile: "ۋاكالەتچى          تېلېفونى",
    editMobileHint: "ۋاكالەتچى تېلېفون نومۇرنى كىرگۈزۈڭ",
    editMobileLength: "11 خانىلىق تېلېفون نومۇرنى كىرگۈزۈڭ",
    editLevel: "ۋاكالەتچى        دەرىجىسى",
    editProxySwitch: "ۋاكالەتچى قوبۇل قىلسا بولامدۇ",
    editLevelHint: "ۋاكالەتچى دەرىجىسىنى تاللاڭ",
    editPercent: "ۋاكالەتچى           پىرسەنتى",
    editParentMobile: "يۇقىرى  ۋاكالەتچى  تېلېفونى",
    editParentMobileHint: "يۇقىرى ۋاكالەتچى تېلېفون نومۇرنى كىرگۈزۈڭ",
    editParantPercent: "يۇقىرى ۋاكالەتچى پىرسەنتى",
    editTitle: "ۋاكالەتچى قوشۇش",
    editTitleEdit: "ۋاكالەتچى تەھرىرلەش",
    editOk: "مۇقىملاش",
    editCancel: "قالدۇرۇش",
    all: "ھەممىسى",
    owner: "ۋاكالەتچىسى بار",
    notOwner: "ۋاكالەتچىسى يوق",
    reviewing: "تەستىق كۈتىۋاتقان",
    reviewed: "تەستىقتىن ئۆتكەن",
    editProxyStatusSwitch: "تەستىقتىن ئۆتكەن",
    editIsPartnerSwitch: "ھەمكارلاشقۇچى",
    editIsShopOwnerSwitch: "دۇكاندار",
    registerDate: "ئىلتىماس ۋاقتى",
    listProxyTeam: "ۋاكالەتچى سانى",
    listProxyClient: "خېرىدار سانى",
    listProxyOrders: "زاكاز سانى",
    apply: "ۋاكالەتچى بولۇش",
  },
  commissionList: {
    mobile: "يانفون",
    order_no: "زاكاز نومۇرى",
    original: "زاكاز ئەسلى باھاسى",
    price: "زاكاز سوممىسى",
    ship: "كىرا",
    real: "تۆلىگىنى",
    receiver: "تاپشۇرىۋالغۇچى",
    tel: "تېلفونى",
    address: "ئ‍ادىرىسى",
    comm: "پايدىسى",
    channel: "كېلىشى",
    status: "ھالىتى",
    order_id: "زاكاز ID",
    unfreeze: "ئېرىتىش",
    confirmFinished: "تاماملاش",
    update: "پوسۇلكا يېڭىلاش",
    confirmReceived: "مال تاپشۇرۇلدى",
    confirmSend: "مال سېلىندى",
    confirm: "تەستىقلاش",
    reject: "رەت قىلىش",
    appliedAmount: "ئىلتىماس قىلغان سوممىسى",
    appliedDate: "ئىلتىماس قىلغان ۋاقىت：%{date}",
    sendWarehouse: "ئامبارغا يوللاش",
  },
  selectLang: {
    zh: "简体中文",
    ug: "ئۇيغۇرچە",
    currentTitle: "ھازىر تاللانغان تىل",
    availableTitle: "تاللاشقا بولىدىغان تىللار",
    switchConfirm: "راستىنلا بۇ تىلنى تاللامسىز ؟",
  },
  importExecl: {
    importing: "جەدۋەل ئوقىلىۋاتىدۇ...",
    historyItemStatusSuccess: "ئوقۇش مۇۋەپپەقىيەتلىك بولدى",
    historyItemStatusFailed: "ئوقۇش مەغلۇب بولدى",
    errorTitle: "خاتالىق ئۇچۇرى",
  },
  sales: {
    upload: "يوللاش",
    status: "ھالىتى",
    submit: "تاپشۇرۇش",
    generate: "ئەپچاق ھاسىللاش",
    deleteWarning: "ئۆچۈرەمسىز؟",
    zhTitleWarning: "خەنزۇچە ئىسمىنى تولدۇرۇڭ",
    zhTitle: "خەنزۇچە ئىسمى",
    ugTitleWarning: "ئۇيغۇرچە ئىسمىنى تولدۇرۇڭ",
    ugTitle: "ئۇيغۇرچە ئىسمى",
    save: "يەرلىككە ساقلاش",
    localOk: "داۋام",
    localNew: "يېڭىدىن باشلاش",
    localWarning: "پۈتمىگەن پائالىيەت بايقالدى، داۋاملاشتۇرامسىز؟",
    priceLabel: "ئەسلى￥%{original_price}يۈەن, ھازىر￥%{price}يۈەن",
    ad: {
      lottery: "چاقپەلەك",
      live: "نەق مەيدان",
      webview: "تور بەت",
      add: "ئىلان قوشۇش",
      weapp: "ئەپچاق",
      appId: "ئەپچاقنىڭ APP_ID سى",
      typeWarning: "ئىلان تۈرىنى تاللاڭ",
      product: "مەھسۇلات",
      holiday: "پائالىيەت",
      ranking: "شەرەپ تاختىسى",
      market: "دۇكان",
      groupon: "تالىشىپ سېتىۋىلىش",
      title: "ئىلان تەھرىرلەش",
      order: "تەرتىپى",
      orderPlace: "نۇمۇرى قانچە چوڭ بولسا شۇنچە ئالدىغا تىزىلىدۇ",
      zhImg: "خەنزۇچە رەسىم",
      ugImg: "ئۇيغۇرچە رەسىم",
      imgWarning: "رەسىم يوق",
      type: "تۈرى",
      target: "ئۇلىنىشىنى تاللاڭ",
      show: "ئىلان",
      swiperTip: "باش بەتتىكى سىيرىلما ئىلان ئورنى، رازمېرى 408px*1040px",
      splashTip:
        "ئەپچاقنى ئاچقاندىكى تولۇق ئىكران تەتۈر ساناق بىلەن چىقىدىغان ئىلان",
      popupTip: "ئەپچاق باش بېتى ئىچىلغاندا سەكرەپ چىقىدىغان ئىلان",
      homeAdTip:
        "باش بەتتىكى مەھسۇلات تىزىملىكىنىڭ ئۈستىگە چىقىدىغان رەسىملىك ئىلان، تەگلىكسىز رەسىم قوللايدۇ، رازمېرى 180px*576px",
    },
    activity: {
      add: "پائالىيەت قوشۇش",
      addTheme: "添加模版",
      product: "مەھسۇلات قوشۇش",
      title: "پائالىيەت",
      zhImg: "خەنزۇچە رەسىم",
      ugImg: "ئۇيغۇرچە رەسىم",
      imgWarning: "رەسىم يوق",
      edit: "تەھرىرلەش",
      delete: "ئۆچۈرۈش",
      today: "بۈگۈن",
      start_date: "باشلىنىش",
      end_date: "ئاخىرلىشىش",
      add_product: "مەھسۇلات قوشۇش",
      time: "پائالىيەت ۋاقتى",
      product_list: "مەھسۇلات تىزىملىكى",
      discount_price: "پائالىيەت باھاسى(يۈەن)",
      discountWarning: "پائالىيەت باھاسىنى كىرگۈزۈڭ",
      min: "ئەڭ تۆۋەن چەك",
      minWarning: "ئەڭ تۆۋەن چەكنى كىرگۈزۈڭ",
      max: "ئەڭ يۇقىرى چەك",
      maxWarning: "ئەڭ يۇقىرى چەكنى كىرگۈزۈڭ",
    },
    groupon: {
      add: "يېڭى تالىشىپ سېتىۋىلىش",
      edit: "تەھرىرلەش",
    },
    nav: {
      add: "سەھىپە قوشۇش",
      title: "سەھىپە تەھرىرلەش",
      order: "تەرتىپى",
      orderPlace: "نۇمۇرى قانچە چوڭ بولسا شۇنچە ئالدىغا تىزىلىدۇ",
      target: "ئۇلىنىشى",
      targetWarning: "ئۇلىنىشىنى تاللاڭ",
      img: "رەسىمى",
      imgWarning: "رەسىم يوق",
      coupon: "ئېتىبار كارتىسى بېتى",
      product: "مەھسۇلات تۈرى بېتى",
      childTab: "سەھىپە",
      orders: "بارلىق زاكازلار بېتى",
      discount: "ئالاھىدە باھالىق بېتى",
      liveList: "نەق مەيدان تىزىملىك بېتى",
      liveRoom: "نەق مەيدان ئۆيى",
      mediaUrl: "فىلىم ئادىرىسىنى ئېلىش",
      roomId: "ئۆي نومۇرى",
      roomIdWarning: "ئۆي نومۇرىنى كىرگۈزۈڭ",
      couponIdWarning: "ئېتىبار كارتىسىنى تاللاڭ",
      imgTip:
        "باش بەتتىكى سىيرىلما ئېلاننىڭ ئاستىدىكى سەھىپە تىزىملىكى، تەگلىكسىز رەسىم قوللايدۇ، رازمېرى 300px*300px",
    },
    mini: {
      name: "ئەپچاق ئىسمى",
      shortName: "قىسقارتىلما ئىسمى",
      description: "چۈشەندۈرۈلىشى",
      img: "باش رەسىم",
      primary: "باش رەڭ",
      secondary: "يانداش رەڭ",
      testTitle: "سىناق تىما",
      testBtnTitle: "سىناق كونۇپكا",
      nameWarning: "ئىسمىنى تولدۇرۇڭ",
      appIdWarning: "AppId نى تولدۇرۇڭ",
      appSecretWarning: "AppSecret نى تولدۇرۇڭ",
      tokenWarning: "Token نى تولدۇرۇڭ",
      aesKeyWarning: "AESKey نى تولدۇرۇڭ",
      descriptionWarning: "ئەپچاقنىڭ چۈشەندۈرىلىشىنى تولدۇرۇڭ",
      imgWarning: "ئەپچاقنىڭ باش رەسىمىنى يوللاڭ",
      mainConfig: "ئەپچاق سەپلىمىسى",
      secondConfig: "ئەپچاق تەڭشىكى",
      paymentConfig: "پۇل تۆلەش تەڭشىكى",
      zhTitle: "خەنزۇچە تېمىسى",
      ugTitle: "ئۇيغۇرچە تېمىسى",
      language: "باش تىلى",
      live: "نەق مەيدان",
      ug: "ئۇيغۇرچە",
      zh: "خەنزۇچە",
      settingWarning:
        "ئەپچاق سەپلىمىسىنى تولدۇرۇپ مۇۋەپپەقىيەتلىك تاپشۇرغاندىن كىيىن، ئاندىن تەڭشەكنى تەڭشەڭ",
    },
    coupons: {
      add: "ئېتىبار كارتىسى قوشۇش",
      send: "تارقىتىش",
      title: "ئېتىبار كارتىسىنى تەھرىرلەش",
      price: "ئېتىبار سوممىسى (يۈەن)",
      limit: "قانچىگە توشسا (يۈەن)",
      range: "ئېلىۋېلىش ۋاقتى",
      experation: "ئىناۋەتلىك ۋاقتى",
      priceWarning: "ئېتىبار سوممىسىنى تولدۇرۇڭ",
      limitWarning: "زاكاز سوممىسىنى تولدۇرۇڭ",
      rangeWarning: "ئېلىۋىلىش ۋاقتىنى تاللاڭ",
      experationWarning: "ئىناۋەتلىك ۋاقتىنى تاللاڭ",
      select: "تاللاش",
      selected: "تاللاندى",
      quantity: "سانى",
      quantityWarning: "كارتا سانىنى تولدۇرۇڭ",
      status: "ھالىتى",
      total: "%{count} تال بار",
      left: "%{count} تال قالدى",
      start: "%{price} يۈەنگە توشسا",
      face_price: "%{price} يۈەن ئېتىبار",
      no_limit: "توسالغۇسىز",
      all: "ھەممىسى",
      used: "ئىشلىتىپ بولغان",
      unused: "ئىشلەتمىگەن  ",
      distributedDate: "تارقىتىلغان ۋاقتى:",
      distributedList: "تارقىتىش تارىخى",
      usedDate: "ئىشلەتكەن ۋاقتى:",
      orderNo: "زاكاز نومۇرى",
      isPublic: "ئاممىباپمۇ؟",
    },
    gift: {
      add: "سوۋغات قوشۇش",
      edit: "تەھرىرلەش",
      product: "مەھسۇلاتقا",
      order: "زاكاز سوممىسىغا",
      type: "سوۋغات تىپى",
      orderAmount: "زاكاز سوممىسى",
      selected: "تاللانغىنى",
      deleteAll: "ھەممىنى ئۆچۈرۈش",
      rangeWarning: "ۋاقتىنى تاللاڭ",
      gift: "سوۋغات",
      typeWarning: "سوۋغات تىپىنى تاللاڭ",
    },
  },
  property: {
    addChild: "ئۆلچەم قوشۇش",
    delete: "ئۆلچەم گورۇپپىسىنى يۇيۇش",
    addGroup: "ئۆلچەم گورۇپپىسى قوشۇش",
    newGroup: "يېڭى ئۆلچەم گورۇپپىسى",
    selectGroup: "ئۆلچەم گۇرۇپپىسى تاللاڭ",
    submit: "مۇقىملاش",
    groupZh: "گورۇپپىسىنىڭ خەنزۇچە ئىسمى",
    groupUg: "گورۇپپىسىنىڭ ئۇيغۇرچە ئىسمى",
    childZh: "ئۆلچەمنىڭ خەنزۇچە ئىسمى",
    childUg: "ئۆلچەمنىڭ ئۇيغۇرچە ئىسمى",
    number: "مەھسۇلات نومۇرى",
    quantity: "ئامبار سانى",
    must: "چوقۇم تولدۇرۇڭ",
    deleteGroup:
      "بۇنى ئۆچۈرسىڭىز، بۈ تۈرگە كىرگۈزگەن خاسلىق ئۇچۇرىلىرى تەڭ ئۆچۈپ كېتىدۇ راستىنلا داۋاملاشتۇرامسىزئ؟",
    priceWarning: "باھاسىنى كىرگۈزڭ",
    quantityWarning: "سانىنى كىرگۈزۈڭ",
  },
  payment: {
    merchantId: "سودىگەر نومۇرى",
    secret: "سودىگەر ئاچقۇچى",
  },
  agentLevel: {
    add: "دەرىجە قوشۇش",
    zh: "خەنزۇچە ئىسمى",
    ug: "ئۇيغۇرچە ئىسمى",
    percent: "پىرسەنتى",
    product: "سېتىۋالىدىغان مەھسۇلات",
  },
  users: {
    select: "ئەزا تاللاش",
    name: "ئىسمى",
    avatar: "باش سۈرىتى",
    tags: "تىزىملاتقانمۇ",
    actions: "مەشغۇلات",
    unbind: "ئۈندىدار يېشىش",
    registered: "تىزىملاتقان",
    unregistered: "تىزىملاتمىغان",
    order: "زاكاز سانى",
    searchPlace: "ئەزا ئىسمى ياكى تېلفون نومۇرىنى كىگۈزۈپ ئىزدەڭ",
    balance: "قالدۇقى",
    balanceSum: "ئومومىي قالدۇق: ",
    date: "كىرگەن ۋاقتى",
    unbindWarning: "باغلاقتىن يېشەمسىز؟",
    currentBalance: "ھازىرقى قالدۇقى",
    topup: "پۇل تولۇقلاش",
    topupWarning: "مۇشۇ ئەزاغا پۇل قاچىلامسىز؟",
    recall: "بىكار قىلىش",
    recallWarning: "بىكار قىلامسىز؟",
    operator: "مەشغۇلاتچى",
    appoint: "بەلگىلەش",
    unappoint: "بەلگىنى ئۆچۈرۈش",
    confirmReducing: "راستىنلا ئۆزگەرتەمسىز",
    reduceAmount: "سومىسى",
    reduceComment: "ئەسكەرتىش (بانكا پۇل ئۇرۇش نومۇرى، چېسلا دېگەندەك)",
    columnRequired: "بۇ ئۇچۇرنى چوقۇم تولدۇرىشىڭىز كېرەك",
  },
  live: {
    edit: "نەق مەيدان ئۆيىنى تەھرىرلەش",
    sync: "ماس قەدەملەش",
    ugTitle: "ئۇيغۇرچە تېمىسى",
    zhTitle: "خەنزۇچە تېمىسى",
    order: "تەرتىپى",
    zh_poster_image: "خەنزۇچە ئېلان رەسىمى",
    ug_poster_image: "ئۇيغۇرچە ئېلان رەسىمى",
    rec: "تەۋسىيەلەنگەن",
    zh_recommanded: "خەنزۇچە تەۋسىيە رەسىم",
    ug_recommanded: "ئۇيغۇرچە تەۋسىيە رەسىم",
  },
  deliveryman: {
    title: "توشۇغۇچى باشقۇرۇش",
    add: "توشۇغۇچى قوشۇش",
    edit: "توشۇغۇچى تەھرىرلەش",
    zhNameHint: "توشۇغۇچى خەنزۇچە ئىسمىنى تولدۇرۇڭ",
    zhName: "خەنزۇچە ئىسمى",
    ugNameHint: "توشۇغۇچى ئۇيغۇرچە ئىسمىنى تولدۇرۇڭ",
    ugName: "ئۇيغۇرچە ئىسمى",
    mobile: "تېلېفۇن نومۇرى",
    mobileHint: "تېلېفۇن نومۇرىنى توغرا كىرگۈزۈڭ",
    mobileHolder: "تېلېفۇن نومۇرىنى كىرگۈزۈڭ",
    deleteWarning: "راستىنلا ئۆچۈرەمسىز؟",
    atleast: "باغلايدىغان مەھەللىدىن بىرنى تاللاڭ",
  },
  community: {
    title: "رايون باشقۇرۇش",
    edit: "رايون ئۇچۇرىنى تەھرىرلەش",
    add: "رايون قوشۇش",
    zhNameHint: "خەنزۇچە نامىنى كىرگۈزۈڭ",
    zhName: "خەنزۇچە نامى",
    ugNameHint: "ئۇيغۇرچە نامىنى كىرگۈزۈڭ",
    ugName: "ئۇيغۇرچە نامى",
    zhAddress: "خەنزۇچە ئ‍ادرېسىنى كىرگۈزۈڭ",
    zhAddress: "خەنزۇچە ئادرېسى",
    ugAddressHint: "ئۇيغۇرچە ئادرېسىنى كىرگۈزۈڭ",
    ugAddress: "ئۇيغۇرچە ئ‍ادرېسى",
    position: "كوردىناتى",
    positionHint: "كوردىناتىنى تولدۇرۇڭ",
    population: "ئاھالە سانى",
    order: "تەرتىپى",
    addMany: "تۈركۈملەپ قوشۇش",
    polygon: "رايون تاللاش",
    success: "مەھەللە مۇۋەپپەقىيەتلىك قېتىلدى",
  },
  lottery: {
    title: "چەك تارتىش پائالىيىتى",
    wheel: "چاقپەلەك",
    nine: "ئايلانما چىراق",
    fruit: "يولۋاس ماشىنىسى",
    product: "مەھسۇلات",
    red: "قىزىل بولاق",
    thanks: "رەھمەت",
    itemType: "مۇكاپات تۈرى",
    itemTypeWarning: "مۇكاپات تۈرىنى تاللاڭ",
    percent: "ئ‍ىھتىماللىقى",
    remainError: "يەنە %{remain} قالدى",
    percentMessage: "ئىھتىماللىقىنىڭ يىغىندىسى چوقۇم 100 پىرسەنت بولۇشى كىرەك",
    add: "مۇكاپات قوشۇش",
    type: "مۇكەپات تارتىش شەكلى",
    empty: "مۇكاپات قوشۇڭ",
    redWarning: "قىزىل بولاق سوممىسىنى تولدۇرۇڭ",
    prizes: "مۇكاپاتلار",
    prirzeTotal: "تارقىتىلغان سانى",
    wheelWarning: "چاخپىلەكتە چوقۇم ئالتە مۇكاپات بولۇشى كىرەك",
    fruitWarning: "مىۋە ماشىنىسىدا چوقۇم سەككىز مۇكاپات بولۇشى كىرەك",
    themeWarning: "مۇكاپات ئۇسلۇبىنى تاللاڭ",
    validTime: "ئىناۋەتلىك ۋاقتى",
    validWarning: "ئىناۋەتلىك ۋاقتىنى تولدۇرۇڭ",
    validRule:
      "مۇكاپاتقا ئېرىشكەن كۈندىن باشلاپ ھىساپلىنىدۇ، ئۆتۈپ كەتسە مۇكاپات ئىناۋەتسىز",
    ticketCount: "بىلەت سانى",
    recommendCount: "سېتىلغان سانى",
    recommendAmount: "سېتىلغان سوممىسى",
    recommendCommission: "تاپقان پۇلى",
    recommendList: "تەشۋىقات ئەھۋالى",
    currentCount: "بىلەت سانى خاتىرىسى",
    prizeCount: "مۇكاپات خاتىرىسى",
    transCount: "يۆتكىلىش خاتىرىسى",
    reduce: "بىلەت ئازلىتىش",
    revert: "قايتۇرۇش",
    zhStartImg: "باشلاش كونۇپكىسى (خەنزۇچە)",
    ugStartImg: "باشلاش كونۇپكىسى (ئۇيغۇرچە)",
    zhHeaderImg: "پائالىيەت تېمىسى (خەنزۇچە)",
    ugHeaderImg: "پائالىيەت تېمىسى (ئۇيغۇرچە)",
    plateBack: "تەخسە تەگلىكى",
    ugDesc: "چۈشەندۈرۈلىشى (ئۇيغۇرچە)",
    zhDesc: "چۈشەندۈرۈلىشى (خەنزۇچە)",
    zhAwardImg: "مۇكاپات رەسىمى (خەنزۇچە)",
    ugAwardImg: "مۇكاپات رەسىمى (ئۇيغۇرچە)",
    thumb: "ئۇسلۇب رەسىمى",
    zhImg: "باش بەت تەگلىك رەسىمى (خەنزۇچە)",
    ugImg: "باش بەت تەگلىك رەسىمى (ئۇيغۇرچە)",
    backColor: "باش بەت تەگلىك رەڭگى",
    imgRule: "رەسىنىڭ رازمېرى %{pixel} تىپى %{type} بولسا بولىدۇ",
    backColorRule: "تەگلىك رەسىمنىڭ رەڭگى ئېلىنسا ياخشى چىقىدۇ",
    sender: "تارقاتقۇچى",
    receiver: "تاپشۇرۇۋالغۇچى",
    telWarning: "تېلېفۇن نومۇرىنى كىرگۈزڭ",
    receiverWarning: "ئىسمىنى كىرگۈزڭ",
    addressWarning: "ئادرېسىنى تولۇق كىرگۈزڭ",
    drew_at: "مۇكاپات تارتقان ۋاقىت",
    exchanged_at: "ئالماشتۇرغان ۋاقىت",
    prize: "مۇكاپات",
    draw: "مۇكاپات تارتقىنى",
    exchange: "تىگىشىپ بولغىنى",
    organSend: "ئورگان تارقاتقىنى",
    userTickets: "ئەزالارنىڭ قولىدىكى",
    ticketPrice: "بىلەت باھاسى (يۈەن)",
    ticketWarning: "بىلەت باھاسىنى كىرگۈزۈڭ",
    toExchange: "نەقلەش",
    exchangeAlert: "نەقلەمسىز؟",
    selfBuy: "خېرىدار بىلەت سېتىۋالالامدۇ؟",
    onSite: "مۇكاپاتنى دۇكاندا ئالامدۇ؟",
    selfBuyCount: "ئۆزى سېتىۋالغان",
    buyHistory: "بىلەت سېتىۋىلىش تارىخى",
    select: "تاللاش",
    left: "ئېشىپ قالغىنى",
    prePrice: "ئالدىن بەلگىلەش",
    addPrePrize: "يېىدىن قوشۇش",
    selectManager: "باشقۇرغۇچىنى تاللاڭ",
    managers: "باشقۇرغۇچىلار",
    opened: "ئوچۇق",
  },
  redPacket: {
    zhImg: "قىزىل بولاق (خەنزۇچە)",
    ugImg: "قىزىل بولاق (ئۇيغۇرچە)",
    price: "(يۈەن)قىممىتى",
    count: "ئادەم سانى",
    add: "قىزىل بولاق قوشۇش",
    update: "تەھرىرلەش",
    atLeast: "ئەڭ ئاز دىگەندە %{price} يۈەن لازىم",
    priceWarning: "سوممىسىنى كىرگۈزۈڭ",
    countWarning: "ئادەم سانىنى كىرگۈزۈڭ",
    list: "خاتىرىسى",
    user: "ئېرىشكۈچى",
    retry: "قايتا ھاسىللاش",
  },
  article: {
    add: "ماقالە قوشۇش",
    addCatPlz: "ئاۋۋال ماقالە تۈرى قوشۇڭ",
    cat: "ماقالە تۈرى",
    title: "ماقالە",
    all: "ھەممىسى",
    opened: "ئېچىقلىق",
    closed: "تاقاقلىق",
    selectWarning: "ماقالىنى تاللاڭ",
  },
  weapp: {
    add: "يېڭى نەشىر يوللاش",
    version: "نەشرى",
    type: "تىپى",
    build: "zip ھۆججىتى",
    standard: "ئۆلچەملىك",
    lottery: "مۇكاپاتلىق",
    course: "دەرسخانا",
    drag: "zip ھۆججىتىنى تاللاڭ ياكى تارتىپ مۇشۇ رايونغا ئەكىرىڭ",
  },
  transfer: {
    new: "پۇل ئۇرۇش",
    reason: "ئىزاھاتىنى يېزىڭ",
  },
  applyment: {
    apply: "ئىلتىماس",
    applyAgain: "قايتا ئىلتىماس",
    query: "تەكشۈرۈش",
    applyDescription:
      "1.ئ‍ىجازەت ئۇچۇرىنى يوللاڭ \n 2. بانكا ئۇچۇرىنى يوللاڭ \n 3. تىجارەت ئۇچۇرىنى يوللاڭ \n 4.ئ‍ەڭ ئاخىرقى باسقۇچ",
    procesing: "بىر تەرەپ قىلىنىۋاتىدۇ",
    processingDescription: "",
    finishDescription: "",
    title: "ئۈندىدار پۇل تۆلەش سىستېمىسىغا ئىلتىماس قىلىش",
    id: "ئىلتىماس نومۇرى",
    status: "ئىلتىماس ھالىتى",
    merchantId: "دۇكان نومۇرى",
    subMerchantId: "ئۈندىدار دۇكان نومۇرى",
    createdDate: "ئىلتىماس قىلىنغان ۋاقىت",
    updatedDate: "يېڭىلانغان ۋاقىت",
    check: "تەكشۈرۈش",
    checking: "ماتېرياللار تەكشۈرىلىۋاتىدۇ",
    accountNeedVerify: "بانكا ھېساباتى دەلىللنىشى كېرەك",
    auditing: "تەستىقلىنىۋاتىدۇ",
    rejected: "رەت قىلىندى",
    canceled: "قالدۇرۇلغان",
    needSign: "كېلىشىم باسقۇچى",
    finish: "تاماملاندى",
    frozen: "توڭلىتىلدى",
  },
  member: {
    memberType: "ئەزالىق شەكلى",
    courseMember: "دەرسلىك ئەزالىقى",
    allMember: "ئ‍ەپچاق ئەزالىقى",
    info: "ئەزا ئۇچۇرى",
    number: "ئەزا نومۇرى",
    joined_at: "ئەزا بولغان",
    expired_at: "توشۇدىغان ۋاقتى",
    saved: "تىجەلگىنى (يۈەن)",
    add: "يېڭى ئەزا قوشۇش",
    duration: "مۇددىتى",
    type: "ئەزا تىپى",
    user: "ئالىي ئەزا",
    selectUser: "ئابونتنى تاللاش",
    reselect: "قايتا تاللاش",
    durationWarning: "مۇددەتنى تاللاڭ",
    cardWarning: "ئەزا تىپىنى تاللاڭ",
    zhDesc: "خەنزۇچە",
    ugDesc: "ئۇيغۇرچە",
    price: "ئەزا ھەققى",
    points: "جۇغلانما",
    discount: "ئېتىبار",
    multiple: "جۇغلانما ھەسسىلىكى",
    addCard: "ئەزا كارتىسى قوشۇش",
    editCard: "ئەزا كارتىشى تەھرىرلەش",
    paidAt: "پۇل تۆلىگەن ۋاقتى",
    independentCommission: "ئايرىم پايدا ئ‍ايرىش ئۇچۇرى تەڭشەمسىز",
    memberAdventages: "ئەزا ھوقوقى",
    shouldAllCourseFree: "ھەممە دەرىسنى ھەقسىز كۆرەمدۇ؟",
    shouldAllCourseFreeDescription:
      "بۇ ئىقتىدار ئېچىۋىتىلگەندىن كېيىن، خېرىدار بۇ ئەزالىق ھوقوقىنى سېتىۋالسا، دەرىسلەرنى ھەقسىز كۆرەلەيدۇ",
    discountDescription:
      "ئەپچاقتى مال سېتىۋىلىنسا بەھرىمان بولىدىغان ئېتىبار، مەسىلەن 10% دەپ تولدۇرۇلسا، ئەسلى باھاسىدىن 10% تۆۋەن ئالالايدۇ.",
    multiplePointsDescription:
      "ئەگەر جۇغلانما ئېقتىدارىنى ئېچىۋەتكەن بولسىڭىز، خېرىدار ھەربىر يۈەن خەجلىسە بىر نومۇر قېتىلىپ ماڭىدۇ.",
  },
  media: {
    supportedImageType: "نۇسخىنى قوللايدۇ %{imageType}",
    supportedAudioType: "نۇسخىنى قوللايدۇ %{audioType}",
    supportedVideoType: "نۇسخىنى قوللايدۇ %{videoType}",
    fileName: "ھۆججەت نامى",
    upload: "يوللاش",
    fileInfo: "ھۆججەت ئۇچۇرى",
    size: "چوڭلۇقى",
    newFolder: "يېڭى مۇندەرىجە",
    folderName: "مۇندەرىجە نامى",
    rename: "ئىسمىنى ئۆزگەرتىش",
    addSubFolder: "بالا مۇندەرىجە قوشۇش",
    deleteFolder: "مۇندەرىجەنى ئۆچۈرۈش",
    selectedRows: "مەزمۇننى تاللىدىڭىز %{count}",
    saveFinished: "ساقلاش مۇۋەپپەقىيەتلىك بولدى",
    selectVideo: "فېلىم تاللاش",
    selectImages: "رەسىم تاللاش",
    selectAudio: "ئاۋاز تاللاش",
  },
  course: {
    title: "دەرىسلەر",
    otherTitle: "دەرىسكە مۇناسىۋەتلىك ئۇچۇرلار",
    titleArticle: "رەسىملىك دەرىسلەر",
    titleAudio: "ئاۋازلىق دەرىسلەر",
    titleVideo: "فىلىملىك دەرىسلەر",
    edit: "دەرىسنى تەھرىرلەش",
    add: "دەرىس قوشۇش",
    addArticle: "رەسىملىك دەرىس قوشۇش",
    addAudio: "ئاۋازلىق دەرىس قوشۇش",
    addVideo: "فىلىملىك دەرىس قوشۇش",
    editArticle: "رەسىملىك دەرىسنى تەھرىرلەش",
    editAudio: "ئاۋازلىق دەرىسنى تەھرىرلەش",
    editVideo: "فىلىملىك دەرىسنى تەھرىرلەش",
    basicInfo: "ئاساسىي ئۇچۇر",
    saleInfo: "سېتىۋىلىش ئۇسۇلى",
    commissionInfo: "پايدا ئ‍ايرىش",
    otherInfo: "باشقا ئۇچۇرلار",
    ugName: "دەرىس نامى (ئۇيغۇرچە)",
    name: "دەرىس نامى",
    zhName: "دەرىس نامى (خەنزۇچە)",
    ugSubTitle: "قسقىچە تونۇشتۇرلىشى (ئۇيغۇرچە)",
    zhSubTitle: "قسقىچە تونۇشتۇرلىشى (خەنزۇچە)",
    selectTeacher: "مۇئەللىمنى تاللاش",
    selectCategory: "تۈرىنى تاللاش",
    goToTeachers: "مۇئەللىم تاللاش",
    goToCategories: "تۈر تاللاش",
    ugBrief: "سىناپ كۆرۈش (ئۇيغۇرچە)",
    zhBrief: "سىناپ كۆرۈش (خەنزۇچە)",
    ugDescription: "دەرىس تەپسىلاتى (ئۇيغۇرچە)",
    zhDescription: "دەرىس تەپسىلاتى (خەنزۇچە)",
    defaultView: "كۆرۈلىشى",
    actualView: "ھەقىقى كۆرۈلىشى",
    defaultSold: "سېتىلىشى",
    actualSold: "ھەقىقى سېتىلىشى",
    marketPrice: "بازار نەرقى",
    price: "باھاسى",
    status: "ھالەت",
    statusActive: "جازىغا چىقىرىش",
    statusInActive: "توختاپ تۇرۇش",
    commissionPercent: "پايدا نىسبىتى",
    parentCommissionPercent: "ئاتا ۋاكالەتچىنىڭ پايدا نىسبىتى",
    validDate: "ئىناۋەتلىك ۋاقىت",
    validForever: "ئۇزاققىچە ئىناۋەتلىك",
    validDays: "سېتىۋالغاندىن كېيىنكى ئىناۋەتلىك كۈن سانى",
    cover: "دەرىس مۇقاۋىسى",
    zhCover: "دەرىس مۇقاۋىسى (خەنزۇچە)",
    ugCover: "دەرىس مۇقاۋىسى (ئۇيغۇرچە)",
    teacherLabel: "مۇئەللىم:",
    courseInfo: "دەرىس ئۇچۇرى",
    categoryName: "دەرىسنىڭ تۈرى",
    studyStatus: "ئۈگىنىش ئەھۋالى",
    teacher: {
      title: "مۇئەللىم تەڭشەش",
      name: "ئىسمى",
      ugName: "ئىسمى (ئۇيغۇرچە)",
      zhName: "ئىسمى (خەنزۇچە)",
      add: "مۇئەللىم قوشۇش",
      edit: "مۇئەللىم ئۆزگەرتىش",
      gender: "جىنىسى",
      male: "ئەر",
      female: "ئايال",
      ugDescription: "سۆزلۈگۈچى (ئۇيغۇرچە)",
      zhDescription: "سۆزلۈگۈچى (خەنزۇچە)",
    },
    category: {
      title: "تۈر تەڭشەش",
      name: "تۈرنىڭ ئىسمى",
      ugName: "تۈرنىڭ ئىسمى (ئۇيغۇرچە)",
      zhName: "تۈرنىڭ ئىسمى (خەنزۇچە)",
      add: "تۈر قوشۇش",
      edit: "تۈر تەڭشەش",
    },
    lesson: {
      title: "ۋاقىت تەڭشەش",
      name: "دەرىسنىڭ نامى",
      ugName: "دەرىسنىڭ نامى (ئۇيغۇرچە)",
      zhName: "دەرىسنىڭ نامى (خەنزۇچە)",
      add: "دەرىس قوشۇش",
      edit: "دەرىس تەڭشەش",
      duration: "دەرىس ۋاقتى",
      trial: "سىناپ كۆرۈش",
      trialDuration: "(سېكۇنت) سىناپ كۆرۈش ۋاقتى",
      lessonVideo: "دەرىس فىلىمى",
      lessonAudio: "دەرىس ئاۋازى",
    },
    student: {
      studyLevel: "ئۈگىنىپ نەگە كەلدى",
      add: "ئوقۇغۇچى قوشۇش",
      info: "ئوقۇغۇچى ئۇچۇرى",
      studyTime: "ئۈگىنىش ۋاقتى",
      studyProgress: "تاماملىنىشى",
      validTime: "ئىناۋەتلىك ۋاقىت",
      validForever: "ئۇزاققىچە ئىناۋەتلىك",
      customDate: "مۇددەت تاللاش",
      customDateLabel: "مۇددىتى",
    },
    comment: {
      title: "باھا باشقۇرۇش",
    },
    order: {
      title: "دەرىسلىك زاكاسلارنى باشقۇرۇش",
      customerInfo: "خېرىدار ئۇچۇرى",
      paidAt: "سېتىۋىلىنغان ۋاقتى",
      price: "سوممىسى",
      realPayment: "تۆلەنگەن پۇلى",
      detail: "زاكاس تەپسىلاتى",
      idLabel: "زاكاس نومۇرى: ",
      transactionIdLabel: "سودا نومۇرى: ",
      statusLabel: "زاكاس ھالىتى: ",
      createTimeLabel: "زاكاس چۈشۈرۈلگەن ۋاقتى: ",
      payTimeLabel: "پۇل تۆلەنگەن ۋاقتى: ",
      avatarLabel: "خېرىدار رەسىمى: ",
      nicknameLabel: "خېرىدار نامى: ",
      telLabel: "خېرىدارنىڭ تېلېفون نومۇرى: ",
      realPaymentLabel: "ھەقىقى تۆلەنگەن پۇلى: ",
      commissionInfoLabel: "تەۋسىيە ئۇچۇرى: ",
      commissionLabel: "تەۋسىيە قىلغۇچىنىڭ پايدىسى: ",
      parentCommissionLabel: "ئاتاسىنىڭ پايدىسى: ",
    },
    sliderAd: {
      title: "دەرىسلىك بېتىنىڭ سىيرىلما ئېلانى",
      add: "سىيرىلما ئېلانى قوشۇش",
      edit: "سىيرىلما ئېلانى تەڭشەش",
      name: "سىيرىلما ئېلانىنىڭ نامى",
    },
  },
  link: {
    title: "ئۇلانما باشقۇرۇش",
    select: "تاللاش",
    deSelect: "بىكار قىلىش",
    platformLinks: "بەتلەر",
    selectedProductCategory: "%{category}~تاۋار تۈرى",
    selectedProduct: "%{product}~تاۋار",
    selectedCourseCategory: "%{category}~دەرىس تۈرى",
    selectedCourse: "%{course}~دەرىس",
    selected: "تاللانغان",
    products: "تاۋار تىزىملىكى",
    coursesCategories: "دەرىس تۈرى",
    courses: "دەرىسلىكلەر",
    weapp: "ئەپچاق ئۇلانمىسى",
    wechatAppId: "ئ‍ەپچاقنىڭ AppID سى",
    wechatPath: "ئەپچاقنىڭ بېتى",
    webview: "توربەت ئېلان",
    webviewUrl: "توربەت ئادرېسى",
  },
  link: {
    title: "ئۇلانما تاللىغۇچ",
    select: "تاللاش",
    deSelect: "تاللاشنى قالدۇرۇش",
    platformLinks: "ئەپچاق بەتلىرى",
    selectedProductCategory: "مال تۈرى~%{category}",
    selectedProduct: "مال~%{product}",
    selectedCourseCategory: "دەرس تۈرى~%{category}",
    selectedCourse: "دەرس~%{course}",
    selected: "تاللانغان",
    products: "مال تىزىملىكى",
    coursesCategories: "دەرس تۈرلىرى",
    courses: "دەرسلىك تىزىملىكى",
    weapp: "ئەپچاققا سەكرەش",
    wechatAppId: "ئەپچاقAPPID",
    wechatPath: "ئەپچاق ئۇلانمىسى",
  },
  qrcode: {
    type: "پائالىيەت شەكلى",
    activity: "پائالىيەت",
    title: "بىر مەھسۇلات بىر كود",
    listTitle: "تىزىملىك",
    name: "نامى",
    ugName: "نامى(ئۇيغۇرچە)",
    zhName: "نامى(خەنزۇچە)",
    batch: "تۈركۈم نومۇرى",
    quantity: "سانى",
    palletCapacity: "توپەن چوڭلىقى",
    cartoonCapacity: "چوڭ يەشىك چوڭلىقى",
    boxCapacity: "كىچىك يەشىك چوڭلىقى",
    qrcodeGenerateWarning:
      "ئىككىلىك كودنى ھاسىللامسىز، ھاسىللىغاندىن كېيىن باشقا ئۇچۇرلىرىنى ئۆزگەرتكىلى بولماي دۇ.",
    generateQRcode: "ھاسىللاش",
    excelGenerateWarning: "ھاسىللىغان كودلارنىڭExcelنى چىقىرامسىز？",
    generateExcel: "Excel ھاسىللاش",
    add: "يېڭى ئىككىلك كود",
    edit: "ئىككىلىك كود تەھرىرلەش",
    generatingQrcode: "ھاسىللىنىۋاتىدۇ",
    qrcodeDetailList: "تىزىملىكنى كۆرۈش",
    generatingExcel: "Excelھاسىللىنىۋاتىدۇ",
    downloadExcel: "Excelچۈشۈرۈش",
    prize: "مۇكاپات",
    type: "خاسلىقى",
    redPacketAmount: "سوممىسى",
    regionName: "رايون",
    scannedAt: "سىكانىرلىغان ۋاقتى",
    drawndAt: "مۇكاپات چىققان ۋاقىتى",
    exchangedAt: "نەقلەشتۈرگەن ۋاقتى",
    priority: "مۇھىملىقى",
    priorityRule: "سان قانچە يوقۇرى بولسا شۇنچە مۇھىم بولىدۇ",
    selectQrcode: "ئىككىلىك كود تاللاش",
    goToQrcodes: "ئىككىلىك كود باشقۇرۇش",
    selectRegion: "رايون تاللاش",
    regionRule:
      "ئەگەر مەلۇم رايوندا پائالىيەت قىلماقچى بولسىڭىز ،رايوننى تاللاڭ",
    redPacket: "قىزىل بولاق",
    redPacketWarning: "قىزىل بولاق سوممىسىنى تولدۇرۇڭ",
    productOnSite: "يەنە بىر مەھسۇلات",
    point: "جۇغلانما",
    redPaketWarning: "سوممىسىنى كىرگۈزۈڭ",
    pointWarning: "جۇغلانما نومۇرىنى كىرگۈزۈڭ",
    specialPrize: "بىۋاستە مۇكاپات چىقمايدۇ",
    expensePoints: "بىر قېتىملىق چەك تارتىشقا كېتىدىغان جۇغلانما نومۇرى",
    expensePointsRule: "پەقەت جۇغلانما ئارقىلىق مۇكاپات چىققاندىلا ئىشلەيدۇ",
    onHowManyTimes: "قانچىنجى قېتىم چەك تارتقاندا چىقىدۇ",
    customer: "خېرىدار تىپى",
    prize: "مۇكاپات شەكلى",
    client: "خېرىدار پائالىيىتى",
    shop: "دۇكان پائالىيىتى",
    prizeZero: "ھەر قېتىم چەك تارتىش",
    prizeOne: "بىرىنجى قېتىم چەك تارتىپ، ئارقىدىن جۇغلانما سوۋغا قىلىش",
    prizeTwo: "ھەر قېتىم جۇغلانما سوۋغا قىلىش",
    store: "دۇكان",
    signboard: "ۋىۋىسكا",
    businessLicense: "تىجارەت كىنىشكىسى",
    idCard: "كىملىك",
    applyDate: "ئىلتىماس ۋاقتى",
    approveDate: "تەستىق ۋاقتى",
    signboard: "ۋىۋىسكا",
    license: "تىجارەت كىنىشكىسى",
    idCard: "كىملىك",
    rejectReason: "رەت قىلىش سەۋەبى",
    agreement: "كېلىشىم",
    zhAgreement: "كېلىشىم (خەنزۇچە)",
    ugAgreement: "كېلىشىم (ئۇيغۇرچە)",
    typeCustomer: "خېرىدار كېلىشىمى",
    typeStore: "دۇكان كېلىشىمى",
    addAgreement: "كېلىشىم قوشۇش",
    redeemOrder: "نەقلەشتۈرۈش زاكازلىرى",
  },
  tuangou: {
    shop: "دۇكان",
    location: "ئورنى",
    tuanzhang: "دۇكاندار",
    addShop: "دۇكان قوشۇش",
    address: "ئادرېس",
    coordinate: "كوردىناتى",
    latitude: "پارالېل",
    longitude: "مېردىئان",
    info: "چۈشەندۈرىلىشى",
    sliderAd: {
      title: "دۇكان بەت ئېلانى",
    },
  },
  recommender: {
    title: "ھەمكارلاشقۇچى",
    qrcode: "ئىككىلىك كود",
  },
  redPacketActivity: {
    title: "قىزىل بولاق",
    name: "پائالىيەت نامى",
    date: "پائالىيەت ۋاقتى",
    redPacket: "قىزىل بولاق",
    items: "تىزىملىك",
    generate: "ھاسىللاش",
    amount: "سوممىسى",
    amountDescription:
      "ئومومى سوممىسى 100مىڭدىن تۆۋەن بولىشى، 20 يۈەندىن كۆپ بولىشى كېرەك",
    average: "ھەر بىر كىشىگە ئوتتۇرا ھېساب بىلەن¥%{average} تارقىتىلىدۇ",
    min: "ئەز ئاز سوممىسى",
    max: "ئ‍ەڭ كۆپ سوممىسى",
    quantity: "سانى",
    type: "تۈرى",
    typeDescription:
      "ئەگەر خالىغانچە سوممىلىقنى تاللىسىڭىز، ھەر بىر كىشىنىڭ ئوخشىمايدىغان چىقىدۇ",
    typeSame: "ھەممىسى ئوخشاش",
    typeRandom: "خالىغانچە",
    receivedAt: "مۇكاپاتقا ئېرىشكەن ۋاقىت",
  },
  bd: {
    employee: {
      title: "بازار ئاچقۇچىلار",
      add: "بازار ئاچقۇچى قوشۇش",
      edit: "بازار ئاچقۇچى تەھرىرلەش",
    },
  },
};
