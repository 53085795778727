import { Button, Form, Input, Radio, Spin, Table } from "antd";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./employeeList.scss";
import { employeeListColumns } from "./employeeListColumns";
import EmployeeListHeader from "./EmployeeListHeader";
import { useHistory } from "react-router-dom";
const i18n = Locale.getInstance();

const EmployeeList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { data, error, isLoading } = useQuery(
    ["bd-employees", user.token, page, keyword],
    () =>
      getList("bd/employees", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
        },
        token: user.token,
      })
  );

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("bd-employees");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const breadCrumbData = [
    {
      href: "/main/bd/employees",
      title: i18n.t("bd.employee.title"),
    },
  ];

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  console.log("🚀 ~ EmployeeList ~ data:", data)
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="teacher">
        <EmployeeListHeader keyword={keyword} setKeyword={setKeyword} />
        <Table
          className="list"
          rowKey="id"
          columns={employeeListColumns(
            deleteMutation,
            user.token,
            setLoading,
            history
          )}
          dataSource={data?.data}
          scroll={{
            y: 320,
          }}
          pagination={{
            current: data.current_page,
            total: data.total,
            pageSize: 10,
            onChange: (page) => setPage(page),
            showQuickJumper: true,
          }}
        />
      </div>
    </>
  );
          
};

export default EmployeeList;
