import {
  HomeOutlined,
  ProfileOutlined,
  TagOutlined,
  AccountBookOutlined,
  CodeOutlined,
  BankOutlined,
  TeamOutlined,
  CameraOutlined,
  SettingOutlined,
  PictureOutlined,
  StarOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  VideoCameraOutlined,
  GiftOutlined,
  FileTextOutlined,
  ToolOutlined,
  CarOutlined,
  IdcardOutlined,
} from "@ant-design/icons";

export const sideMenus = [
  {
    key: "/main",
    icon: <HomeOutlined />,
    label: "main.sideMenus.home",
  },
  {
    key: "/main/order",
    icon: <ProfileOutlined />,
    label: "main.sideMenus.order",
    children: [
      {
        key: "/main/order/list",
        icon: null,
        label: "main.sideMenus.orderList",
      },
      {
        key: "/main/order/refund-list",
        icon: null,
        label: "main.sideMenus.refundList",
      },
      {
        key: "/main/order/download",
        icon: null,
        label: "main.sideMenus.downloadList",
      },
      {
        key: "/main/order/import",
        icon: null,
        label: "main.sideMenus.import",
      },
    ],
  },
  {
    key: "/main/delivery",
    icon: <CarOutlined />,
    label: "main.sideMenus.delivery",
    children: [
      {
        key: "/main/delivery/deliveryman",
        icon: null,
        label: "deliveryman.title",
      },
      {
        key: "/main/delivery/community",
        icon: null,
        label: "community.title",
      },
    ],
  },
  {
    key: "/main/product",
    icon: <TagOutlined />,
    label: "main.sideMenus.product",
    children: [
      {
        key: "/main/product/list",
        icon: null,
        label: "main.sideMenus.productList",
        contains: ["/main/product/edit"],
      },
    ],
  },
  {
    key: "/main/manage-ads",
    icon: <StarOutlined />,
    label: "main.sideMenus.manageAds",
    children: [
      {
        key: "/main/manage-ads/ads",
        icon: null,
        label: "main.sideMenus.ads",
      },
      {
        key: "/main/manage-ads/navs",
        icon: null,
        label: "main.sideMenus.navs",
      },
      {
        key: "/main/manage-ads/popups",
        icon: null,
        label: "main.sideMenus.popups",
      },
      {
        key: "/main/manage-ads/splash",
        icon: null,
        label: "main.sideMenus.splash",
      },
      {
        key: "/main/manage-ads/home-ad",
        icon: null,
        label: "main.sideMenus.homeAd",
      },
      {
        key: "/main/manage-ads/user-center-ad",
        icon: null,
        label: "main.sideMenus.userCenterAd",
      },
      {
        key: "/main/manage-ads/become-agent-ad",
        icon: null,
        label: "main.sideMenus.becomeAgentAd",
      },
    ],
  },
  {
    key: "/main/sales",
    icon: <AccountBookOutlined />,
    label: "main.sideMenus.sales",
    children: [
      {
        key: "/main/sales/coupons",
        icon: null,
        label: "main.sideMenus.coupons",
      },
      // {
      //   key: "/main/sales/groupon",
      //   icon: null,
      //   label: "main.sideMenus.groupon",
      // },
      {
        key: "/main/sales/flash",
        icon: null,
        label: "main.sideMenus.flash",
        contains: ["/main/sales/flash-edit"],
      },
      {
        key: "/main/sales/gift",
        icon: null,
        label: "main.sideMenus.gift",
        contains: ["/main/sales/gift"],
      },
    ],
  },
  {
    key: "/main/lottery",
    icon: <GiftOutlined />,
    label: "main.sideMenus.lottery",
    children: [
      {
        key: "/main/lottery/list",
        label: "main.sideMenus.lotteryList",
      },
      {
        key: "/main/lottery/theme",
        label: "main.sideMenus.lotteryTheme",
      },
      {
        key: "/main/lottery/recommender",
        label: "recommender.title",
      },
    ],
  },
  {
    key: "/main/red-packet",
    icon: <ToolOutlined />,
    label: "main.sideMenus.redPacket",
  },
  {
    key: "/main/lottery-qrcode",
    icon: <GiftOutlined />,
    label: "qrcode.title",
    children: [
      {
        key: "/main/lottery-qrcode/list",
        label: "qrcode.listTitle",
      },
      {
        key: "/main/lottery-qrcode/activity",
        label: "qrcode.activity",
      },
      {
        key: "/main/lottery-qrcode/store",
        label: "qrcode.store",
      },
      {
        key: "/main/lottery-qrcode/agreement",
        label: "qrcode.agreement",
      },
      {
        key: "/main/lottery-qrcode/redeem-order",
        label: "qrcode.redeemOrder",
      },
    ],
  },
  {
    key: "/main/article",
    icon: <FileTextOutlined />,
    label: "main.sideMenus.article",
  },
  {
    key: "/main/live",
    icon: <VideoCameraOutlined />,
    label: "main.sideMenus.live",
    children: [
      {
        key: "/main/live/list",
        icon: null,
        label: "main.sideMenus.liveList",
      },
    ],
  },
  {
    key: "/main/mini-program",
    icon: <CodeOutlined />,
    label: "main.sideMenus.mini",
    children: [
      {
        key: "/main/mini-program/setting",
        icon: null,
        label: "main.sideMenus.miniSetting",
      },
    ],
  },
  // {
  //   key: "/main/review",
  //   icon: <EditOutlined />,
  //   label: "main.sideMenus.review",
  //   children: [
  //     {
  //       key: "/main/review/list",
  //       icon: null,
  //       label: "main.sideMenus.reviewList",
  //     },
  //   ],
  // },
  {
    key: "/main/finance",
    icon: <BankOutlined />,
    label: "main.sideMenus.finance",
    children: [
      {
        key: "/main/finance/home",
        icon: null,
        label: "main.sideMenus.financeHome",
      },
      {
        key: "/main/finance/check",
        icon: null,
        label: "main.sideMenus.financeCheck",
        contains: ["/main/finance/check-daily", "/main/finance/order-detail"],
      },
      {
        key: "/main/finance/account",
        icon: null,
        label: "main.sideMenus.financeAccount",
        contains: ["/main/finance/bill-detail"],
      },
      {
        key: "/main/finance/download",
        icon: null,
        label: "main.sideMenus.financeDownload",
      },
      {
        key: "/main/finance/withdraw-list",
        icon: null,
        label: "main.sideMenus.withdrawList",
      },
      {
        key: "/main/finance/send-money-list",
        icon: null,
        label: "main.sideMenus.sendMoney",
      },
      {
        key: "/main/finance/withdraw-request-list",
        icon: null,
        label: "main.sideMenus.withdrawRequestList",
      },
    ],
  },
  {
    key: "/main/members",
    icon: <IdcardOutlined />,
    label: "main.sideMenus.members",
    children: [
      {
        key: "/main/members/list",
        icon: null,
        label: "main.sideMenus.membersList",
      },
      {
        key: "/main/members/card",
        icon: null,
        label: "main.sideMenus.membersCard",
      },
      {
        key: "/main/members/order",
        icon: null,
        label: "main.sideMenus.membersOrder",
      },
    ],
  },
  {
    key: "/main/proxy",
    icon: <TeamOutlined />,
    label: "main.sideMenus.proxy",
    children: [
      {
        key: "/main/proxy/list",
        icon: null,
        label: "main.sideMenus.proxyList",
      },
      {
        key: "/main/proxy/commission-list",
        icon: null,
        label: "main.sideMenus.commissionList",
      },
      {
        key: "/main/proxy/agent-level",
        icon: null,
        label: "main.sideMenus.agentLevel",
      },
    ],
  },
  {
    key: "/main/users",
    icon: <UserOutlined />,
    label: "main.sideMenus.users",
    children: [
      {
        key: "/main/users/list",
        icon: null,
        label: "main.sideMenus.usersList",
      },
    ],
  },
  {
    key: "/main/balance",
    icon: <MoneyCollectOutlined />,
    label: "main.sideMenus.balance",
    children: [
      {
        key: "/main/balance/topup",
        icon: null,
        label: "main.sideMenus.topup",
      },
      {
        key: "/main/balance/spend",
        icon: null,
        label: "main.sideMenus.spend",
      },
      {
        key: "/main/balance/reduce",
        icon: null,
        label: "main.sideMenus.reduce",
      },
      {
        key: "/main/balance/list",
        icon: null,
        label: "main.sideMenus.balanceUserList",
      },
    ],
  },
  {
    key: "/main/shows",
    icon: <CameraOutlined />,
    label: "main.sideMenus.shows",
    children: [
      {
        key: "/main/shows/list",
        label: "main.sideMenus.showsList",
      },
    ],
  },
  {
    key: "/main/weapp-management",
    icon: <ToolOutlined />,
    label: "main.sideMenus.manageWeapp",
  },
  {
    key: "/main/shop",
    icon: <ToolOutlined />,
    label: "tuangou.shop",
    children: [
      {
        key: "/main/shop/list",
        icon: null,
        label: "tuangou.shop",
      },
      {
        key: "/main/shop/slider-ads",
        icon: null,
        label: "tuangou.sliderAd.title",
      },
    ],
  },

  {
    key: "/main/media",
    icon: <PictureOutlined />,
    label: "main.sideMenus.media",
    children: [
      {
        key: "/main/media/files",
        icon: null,
        label: "main.sideMenus.media",
      },

      {
        key: "/main/media/storage-setting",
        icon: null,
        label: "main.sideMenus.storageSetting",
      },
    ],
  },

  {
    key: "/main/course",
    icon: <PictureOutlined />,
    label: "course.otherTitle",
    children: [
      {
        key: "/main/course/teachers",
        icon: null,
        label: "course.teacher.title",
      },
      {
        key: "/main/course/slider-ads",
        icon: null,
        label: "course.sliderAd.title",
      },
      {
        key: "/main/course/categories",
        icon: null,
        label: "course.category.title",
      },
      {
        key: "/main/course/orders",
        icon: null,
        label: "course.order.title",
      },
    ],
  },
  {
    key: "/main/courses",
    icon: <PictureOutlined />,
    label: "course.title",
    children: [
      {
        key: "/main/courses/article-courses",
        icon: null,
        label: "course.titleArticle",
      },
      {
        key: "/main/courses/audio-courses",
        icon: null,
        label: "course.titleAudio",
      },
      {
        key: "/main/courses/video-courses",
        icon: null,
        label: "course.titleVideo",
      },
    ],
  },
  {
    key: "/main/bd",
    icon: <PictureOutlined />,
    label: "bd.employee.title",
    children: [
      {
        key: "/main/bd/employees",
        icon: null,
        label: "bd.employee.title",
      },
    ],
  },
  {
    key: "/main/setting",
    icon: <SettingOutlined />,
    label: "main.sideMenus.setting",
    children: [
      {
        key: "/main/setting/shop",
        icon: null,
        label: "main.sideMenus.settingShop",
      },
      {
        key: "/main/setting/lang",
        icon: null,
        label: "main.sideMenus.selectLang",
      },
      {
        key: "/main/setting/merchant-info-setting",
        icon: null,
        label: "main.sideMenus.merchantSetting",
      },
      {
        key: "/main/setting/applyment-info",
        icon: null,
        label: "main.sideMenus.wechatMerchantApplymentInfo",
      },
    ],
  },
];
