import {Avatar, Space, Tag } from "antd";
import { Locale, Util } from "../../../utils";
import dayjs from "dayjs";
const i18n = Locale.getInstance();
const util = Util.getInstance();
export const redPacketItemListColumns = (
  
) => {
  return [
    {
      title: i18n.t("applyment.createdDate"),
      render: item => {
        return <div>{dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
      }
    },
    {
      title: i18n.t("lottery.prize"),
      render: item => {
        if (item.product) {
          return (
            <>
              <Avatar
                src={item.product.images[0]}
                shape="square"
                size="large"
              />
              <div>{i18n.getPropNameByLocale(item.product)}</div>
              <div>¥{util.getPrice(item.amount )}</div>
            </>
          );
        }
        return <div>¥{util.getPrice(item.amount)}</div>
      }
    },
    {
      title: i18n.t("redPacketActivity.receivedAt"),
      render: item => {
        return <div>{item.received_at ? dayjs(item.received_at).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
      }
    },
    {
      title: i18n.t("redPacket.user"),
      render: (text, record) => {
        if(!record.wechat_user){
          return null;
        }
        return (
        <div>
          <Space>
            <Avatar src={record?.wechat_user?.avatar} />
            <a>{record?.wechat_user?.nickname}</a>
          </Space>
          <div>{record.received_at}</div>
        </div>
      )},
    },
  ];
};
