import { notification } from "antd";
import Locale from "../locale/Locale";
import { useSelector } from "react-redux";
import { getUserInfo } from "../redux/reducers/User";
import { getMerchantSettings } from "../redux/reducers/Merchant";
import { GROUPON_ID, JD_ID, SUPERMARKET_ID } from "../../constants";
const i18n = Locale.getInstance();

export const showNotificationWithIcon = (error, type = "error") => {
  let message = i18n.t("base.modal.errorTitle");
  if (type === "success") {
    message = i18n.t("base.modal.successTitle");
  }

  let description = error;
  if (Array.isArray(error)) {
    description = (
      <div>
        {error.map((info) => {
          return <div>{info}</div>;
        })}
      </div>
    );
  }
  notification[type]({
    message,
    description,
  });
};

export const get422Errors = (error) => {
  let errors = [];
  let keys = error.data ? Object.keys(error.data) : null;

  if (keys && keys.length > 0) {
    errors = [];
    for (let i = 0; i < keys.length; i++) {
      let value = error.data[`${keys[i]}`];
      errors.push(value.toString());
    }
  }
  return errors;
};

export const handleMutationError = (error) => {
  if (error?.status && error.status === 422) {
    showNotificationWithIcon(get422Errors(error.data``));
  } else {
    showNotificationWithIcon(error.message);
  }
};

export function getFormattedDateString(date) {
  if (moment.isDayjs(date)) {
    return date.format("YYYY-MM-DD");
  }
  if (typeof date === "string") {
    return moment(date).format("YYYY-MM-DD");
  }
  return null;
}

export const isBBShopMerchant = () => {
  const user = useSelector((state) => getUserInfo(state));
  merchant = user ? user.merchant : null;
  if (merchant) {
    return [SUPERMARKET_ID, JD_ID, GROUPON_ID].includes(merchant.id);
  }
  return false;
};

export const shopHasAgents = () => {
  const settings = useSelector((state) => getMerchantSettings(state));
  if(settings && settings.disable_agent){
    return false;
  }
  const user = useSelector((state) => getUserInfo(state));

  return user?.merchant && user.merchant.has_agents;
}