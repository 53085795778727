import { sideMenus } from "./sideMenus";
import Locale from "../../utils/locale/Locale";
import { isBBShopMerchant } from "../../utils/helpers/helpers";
const i18n = Locale.getInstance();
export const initSideMenus = () => {
  const menus = sideMenus.map((i) => {
    return {
      ...i,
      label: i18n.t(i.label),
      children: i.children?.map((c) => {
        return { ...c, label: i18n.t(c.label) };
      }),
    };
  });
  

  return menus;
};
